import { OrgType } from '../_shared/interfaces/orgType';
import { Org } from '../_shared/interfaces/org';
import { Location } from '../_shared/interfaces/location';
import { Group } from '../_shared/interfaces/group';
import { Equipment } from '../_shared/interfaces/equipment';
import { Sensor } from '../_shared/interfaces/sensor';
import { Model } from '../_shared/interfaces/model';
import { checkPhoneBreak } from '../utils';
import { Publish } from '../_shared/interfaces/publish';
import { TandemGroup } from '../_shared/interfaces/tandemGroup';
import { get } from 'lodash';
import { Profile } from '../_shared/interfaces/profile';
import { DentalOrgType } from '../_shared/interfaces/dentalOrgType';
import { Preset } from '../_shared/interfaces/preset';

import { User } from '../_shared/interfaces/user';
export interface DashView {
  lastEquipment?: string;
  isPhoneBreak?: boolean;
  equipmentLoading?: boolean;
  equipView: number;
}

export const createEquipMap = () => {
  return {
    alerts: [],
    alertMap: {},
    configs: [],
    thresholds: [],
    schedules: [],
    maint: [],
    sensorStatistics: [],
  };
};

export interface EQMap {
  alerts: Publish[];
  alertMap: {};
  configs: Publish[];
  thresholds: Publish[];
  schedules: Publish[];
  maint: Publish[];
  sensorStatistics: Publish[];
}

export interface EquipMap {
  [key: string]: EQMap;
}

export interface DashState {
  orgTypes: OrgType[];
  orgs: Org[];
  locations: Location[];
  groups: Group[];
  equipment: Equipment[];
  filterEquipment?: Equipment[];
  equipmentFilters: DashEquipFilters;
  view: {};
  sensors: Sensor[];
  models: Model[];
  equipMap: EquipMap;
  tandemGroups: TandemGroup[];
  dentalOrgTypes: DentalOrgType[];
  profiles: Profile[];
  presets?: Preset[];
  users: User[];
}

export interface DashActionTypes {
  type: DashActions;
  payload?: [];
  view?: DashView;
  map?: {};
}

export interface DashEquipFilters {
  search?: string;
  model?: string[];
  serviceOrg?: string[];
  dentalOrg?: string[];
  location?: string[];
  subscriptionStatus?: string;
  status?: string[];
  mode?: string[];
  onConsignment?: boolean;
  isActive?: boolean;
  showOnDash?: number;
  dentalOrgState?: string[];
}

export enum DashActions {
  SET_ORG_TYPES = 'SET_ORG_TYPES',
  SET_ORGS = 'SET_ORGS',
  SET_LOCATIONS = 'SET_LOCATIONS',
  SET_GROUPS = 'SET_GROUPS',
  SET_EQUIPMENT = 'SET_EQUIPMENT',
  SET_FILTER_EQUIPMENT = 'SET_FILTER_EQUIPMENT',
  SET_DASH_VIEW = 'SET_DASH_VIEW',
  SET_SENSORS = 'SET_SENSORS',
  SET_MODELS = 'SET_MODELS',
  SET_MAP = 'SET_MAP',
  SET_USERS = 'SET_USERS',
  SET_TANDEM_GROUPS = 'SET_TANDEM_GROUPS',
  UPDATE_EQUIPMENT_PUSHER = 'UPDATE_EQUIPMENT_PUSHER',
  SET_PROFILES = 'SET_PROFILES',
  SET_PRESETS = 'SET_PRESETS',
  UPDATE_USERS_PUSHER = 'UPDATE_USERS_PUSHER',
  CLEAR_EQUIP_MAP = 'CLEAR_EQUIP_MAP',
}

const initialState: DashState = {
  orgTypes: [],
  orgs: [],
  locations: [],
  groups: [],
  equipment: [],
  tandemGroups: [],
  users: [],
  filterEquipment: undefined,
  equipmentFilters: {
    search: undefined,
    model: undefined,
    serviceOrg: undefined,
    dentalOrg: undefined,
    status: undefined,
    mode: undefined,
    showOnDash: 1,
    dentalOrgState: undefined,
  },
  view: {
    lastEquipment: undefined,
    equipmentLoading: false,
    equipView: 0,
    isPhoneBreak: checkPhoneBreak(),
  },
  sensors: [],
  models: [],
  equipMap: {},
  profiles: [],
  dentalOrgTypes: [],
  presets: [],
};

export const dashReducer = (
  state: DashState = initialState,
  action: DashActionTypes
) => {
  switch (action.type) {
    default:
      return state;
    case DashActions.SET_ORG_TYPES:
      return {
        ...state,
        orgTypes: action.payload || [],
      };
    case DashActions.SET_ORGS:
      return {
        ...state,
        orgs: action.payload || [],
      };
    case DashActions.SET_LOCATIONS:
      return {
        ...state,
        locations: action.payload || [],
      };
    case DashActions.SET_GROUPS:
      return {
        ...state,
        groups: action.payload || [],
      };
    case DashActions.SET_EQUIPMENT:
      return {
        ...state,
        equipment: action.payload || [],
      };
    case DashActions.SET_TANDEM_GROUPS:
      return {
        ...state,
        tandemGroups: action.payload || [],
      };
    case DashActions.SET_FILTER_EQUIPMENT:
      return {
        ...state,
        ...action.payload,
      };
    case DashActions.SET_DASH_VIEW:
      return {
        ...state,
        view: action.view || {},
      };
    case DashActions.SET_SENSORS:
      return {
        ...state,
        sensors: action.payload || [],
      };
    case DashActions.SET_USERS:
      return {
        ...state,
        users: action.payload || [],
      };
    case DashActions.SET_MODELS:
      return {
        ...state,
        models: action.payload || [],
      };
    case DashActions.SET_PROFILES:
      return {
        ...state,
        profiles: action.payload || [],
      };
    case DashActions.SET_PRESETS:
      return {
        ...state,
        presets: action.payload || [],
      };
    case DashActions.SET_MAP:
      return {
        ...state,
        ...action.map,
      };
    case DashActions.CLEAR_EQUIP_MAP:
      return {
        ...state,
        equipMap: {},
      }
    case DashActions.UPDATE_EQUIPMENT_PUSHER:
      const equip = (action.payload as unknown) as Equipment;
      return {
        ...state,
        filterEquipment: state.filterEquipment
          ? state.filterEquipment.map(eq =>
              eq.sk === get(equip, 'sk') ? equip : eq
            )
          : state.filterEquipment,
        equipment: state.equipment.map(eq =>
          eq.sk === get(equip, 'sk') ? equip : eq
        ),
      };
    case DashActions.UPDATE_USERS_PUSHER:
      const _user = (action.payload as unknown) as User;
      const userExists = !!state.users.find(user => {
        return user.userId === get(_user, 'userId');
      });
      if (!!userExists) {
        return {
          ...state,
          users: state.users.map(user => {
            if (user.userId === get(_user, 'userId')) {
              return _user;
            }
            return user;
          }),
        };
      }
      if (!userExists) {
        return {
          ...state,
          users: [...state.users, _user],
        };
      }
      return {
        ...state,
      };
  }
};
