/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { TableList, TableListHeader } from '../../_shared/table-list';
import { Input, Alert, Icon, Checkbox } from 'antd';
import SharedStyles from '../../_shared/styles';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppState } from '../../app.state';
import {
  userHasRole,
  singleStringAddress,
  sortByName,
  getMyOrg,
  sortByLabel,
  isDentalOrgAdmin,
  getMyOrgStrings,
  sortByKey,
  isPB,
} from '../../utils';
import { chain, get, set } from 'lodash';
import { getOrgs } from '../../_shared/services/manage-orgs.service';
import { getEquipments } from '../../_shared/services/manage-equipment.service';
import { getModels } from '../../_shared/services/manage-models.service';
import { Location } from '../../_shared/interfaces/location';
import colors from '../../_shared/colors';
import { Button } from '../../_shared/button';
import { SelectValue } from 'antd/lib/select';
import { Select } from '../../_shared/select';
import { getLocations } from '../../_shared/services/manage-locations.service';
import { Equipment } from '../../_shared/interfaces/equipment';
import { Model } from '../../_shared/interfaces/model';
import Link from '../../_shared/link';

const Columns = (_this: _ManageLocationsComponent) => {
  const { sState } = _this.props;
  const isDOAdmin = isDentalOrgAdmin(sState);

  return [
    userHasRole([0, 1, 2], sState) &&
      !isDOAdmin && {
        title: 'Dental Org',
        dataIndex: 'dentalOrg',
        width: 150,
      },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: (a: Location, b: Location) => sortByKey(a, b, 'name'),
    },
    {
      title: 'Service Org',
      dataIndex: 'serviceOrg',
      width: 150,
    },
    {
      title: 'Address',
      dataIndex: 'addr',
      width: 150,
    },
    {
      title: 'Vacuums',
      dataIndex: 'vCount',
      width: 100,
    },
    {
      title: 'Compressors',
      dataIndex: 'cCount',
      width: 100,
    },
    !isDentalOrgAdmin(sState) && {
      title: 'Edit',
      dataIndex: 'edit',
      width: 50,
      render: (text: string, record: { _location: Location }) => {
        return (
          <Icon
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              _this.addLocation(record._location);
            }}
            type={'edit'}
            css={css(`color: ${colors.highlight};`)}
          />
        );
      },
    },
  ].filter(c => c);
};

interface IProps extends RouteComponentProps {
  sState: AppState;
  orgType: number;
}

class _ManageLocationsComponent extends Component<IProps> {
  state = {
    columns: [...Columns(this)],
    loading: true,
    success: null,
    error: null,
    show: false,
    showInactive: false,
    data: [],
    locations: [],
    allLocations: [],
    orgs: [],
    equipment: [],
    models: [],
    searchValue: null,
    showFilters: true,
    serviceOrgs: {
      canDisplay: () => {
        const isSuper = userHasRole([0, 1], this.props.sState);
        return isSuper;
      },
      opts: () =>
        this.props.sState.dash.orgs
          .filter(o => o.orgType === 0)
          .map(org => ({
            label: org.name,
            value: org.id,
          }))
          .sort(sortByLabel),
      selected: [] as string[],
    },

    dentalOrgs: {
      canDisplay: () => {
        const isSuper = userHasRole([0, 1], this.props.sState);

        if (isSuper) {
          return true;
        } else {
          const myOrg = getMyOrg(this.props.sState);
          if (myOrg && myOrg.orgType === 0) {
            return true;
          } else {
            return false;
          }
        }
      },
      opts: () =>
        this.props.sState.dash.orgs
          .filter(o => o.orgType === 1)
          .map(org => ({
            label: org.name,
            value: org.id,
          }))
          .sort(sortByLabel),
      selected: [] as string[],
    },
  };
  componentDidMount = () => {
    const { state } = this.props.history.location;
    const { sState } = this.props;
    const ispb = isPB(sState);

    if (ispb) {
      this.setState({ showFilters: false });
    }
    if (state && state.toast) {
      this.setState({ success: state.toast });
    }

    const org = get(state, 'org');

    if (org) {
      const name = 'dentalOrgs';
      this.setState(
        {
          show: false,
          [name]: {
            ...get(this.state, name),
            selected: [org.id],
          },
        },
        this.retrieveData
      );
    } else {
      this.retrieveData();
    }
  };

  componentDidUpdate = (prevProps: IProps) => {
    if (
      get(prevProps, 'sState.dash.view.isPhoneBreak') !==
      get(this.props, 'sState.dash.view.isPhoneBreak')
    ) {
      const { sState } = this.props;
      const ispb = isPB(sState);
      if (ispb) {
        this.setState({ showFilters: false });
      } else {
        this.setState({ showFilters: true });
      }
    }
  };

  addLocation = (location?: Location) => {
    this.props.history.push('/dashboard/editLocation', {
      location, //if location is provided we will edit, otherwise it is "new"
    });
  };
  manageLocation = (location: Location) => {
    const loc = `/dashboard/manageLocation`;
    this.props.history.push(loc, {
      location,
    });
  };
  selectLocation = (record: { _location: Location }, index: number) => {
    this.manageLocation(record._location);
  };
  selectedRowsChange = (keys: string[] | number[]) => {
    this.setState({
      selectedRows: keys,
    });
  };
  fireSearch = (value: string) => {
    this.setState({ searchValue: value }, () => this.retrieveData(false));
  };
  retrieveData = async (shouldFetch = true) => {
    const state = { ...this.state };
    const { searchValue, serviceOrgs, dentalOrgs, showInactive } = state;
    const { sState } = this.props;

    const iAmSuperOrTech = userHasRole([0, 1], sState);

    this.setState({ loading: true });

    try {
      const [allLocations, orgs, equipment, models] = await Promise.all([
        shouldFetch ? await getLocations(undefined, !state.showInactive) : state.allLocations,
        shouldFetch ? await getOrgs() : state.orgs,
        shouldFetch ? await getEquipments() : state.equipment,
        shouldFetch ? await getModels() : state.models,
      ]);

      const orgMap = chain(orgs)
        .keyBy('id')
        .value();
      const modelMap = chain(models)
        .keyBy('id')
        .value();

      const eMap: {
        [key: string]: [];
      } = {};
      equipment.map(e => {
        const hasLoc = get(e, 'locationId');
        if (hasLoc) {
          const maps = get(eMap, hasLoc, []);
          set(eMap, hasLoc, [...maps, e]);
        }
      });

      const orgStrings = getMyOrgStrings(sState);
      const locations = (iAmSuperOrTech
        ? [...allLocations]
        : allLocations.filter(l => orgStrings.indexOf(l.orgId || '') > -1)
      ).sort(sortByName);

      const sterm = (searchValue || '').toLowerCase();
      const data = [...locations]
        .map(l => {
          const org = get(orgMap, `${l.orgId}`);
          const serviceOrg = get(orgMap, `${get(org, 'serviceOrgId')}`);
          let vCount = 0;
          let cCount = 0;
          const eqs = get(eMap, `${l.id}`, []) as Equipment[];
          eqs.map(e => {
            const model = get(modelMap, `${e.modelId}`) as Model;
            if (model && model.type) {
              if (model.type === 'vacuum') {
                vCount += 1;
              } else if (model.type === 'compressor') {
                cCount += 1;
              }
            }
          });

          return {
            ...l,
            _location: { ...l },
            dentalOrg: get(org, 'name', ''),
            serviceOrg: get(serviceOrg, 'name', ''),
            addr: singleStringAddress(l.address),
            vCount,
            cCount,
          };
        })
        .filter(o => {
          let passes = true;

          if (searchValue) {
            const oname = `${get(o, 'name', '')}`.toLowerCase();
            const ocode = `${get(o, 'code', '')}`.toLowerCase();

            if (
              (oname && oname.indexOf(sterm) > -1) ||
              (ocode && ocode.indexOf(sterm) > -1)
            ) {
              passes = true;
            } else {
              passes = false;
            }
          }

          if (passes && serviceOrgs.selected.length > 0) {
            const assocDOs = this.props.sState.dash.orgs
              .filter(org => {
                return serviceOrgs.selected.includes(get(
                  org,
                  'serviceOrgId'
                ) as string);
              })
              .map(org => org.id);
            if (!o.orgId || assocDOs.indexOf(o.orgId) === -1) {
              passes = false;
            }
          }

          if (passes && dentalOrgs.selected.length > 0) {
            if (!o.orgId || dentalOrgs.selected.indexOf(o.orgId) === -1) {
              passes = false;
            }
          }

          if (passes && !showInactive) {
            const isActive = get(o, 'isActive', 0) > 0;
            passes = isActive;
          }

          return passes;
        });

      const columns = Columns(this);

      this.setState({
        allLocations: allLocations as never[],
        data: data as never[],
        locations: locations as never[],
        orgs: orgs as never[],
        equipment: equipment as never[],
        models: models as never[],
        show: true,
        columns,
        loading: false,
      });
    } catch (err) {
      console.warn(err);
      this.setState({
        loading: false,
        error: err.message,
      });
    }
  };
  changeSelect = (item: 'dentalOrgs' | 'serviceOrgs', value: SelectValue) => {
    this.setState(
      { [item]: { ...this.state[item], selected: [...(value as string)] } },
      () => this.retrieveData(false)
    );
  };
  render() {
    const {
      columns,
      data,
      loading,
      error,
      success,
      dentalOrgs,
      show,
      showInactive,
      serviceOrgs,
      showFilters,
    } = this.state;

    const canAdd = !isDentalOrgAdmin(this.props.sState);
    const { sState } = this.props;
    const ispb = isPB(sState);

    return (
      <div>
        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <TableListHeader style={ispb && SharedStyles.pbFilterContainer}>
          <div
            css={css(SharedStyles.row, ispb ? `width: 100%` : `width: 50%;`)}
          >
            <Input.Search
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 70%;
                      margin-right: 10px;
                    `
              }
              placeholder="Search by Name or Code..."
              enterButton="Search"
              size="default"
              onSearch={this.fireSearch}
            />

            <Button
              css={ispb && css(`display: none;`)}
              loading={loading}
              title="Refresh"
              type="link"
              onClick={() => this.retrieveData(true)}
            />
          </div>
          <div css={ispb && css(SharedStyles.pbButtonContainer)}>
            {canAdd && (
              <Button
                title={`Add Location`}
                onClick={() => this.addLocation()}
              />
            )}
            <div css={!ispb && css(SharedStyles.hidden)}>
              <Link
                css={css(SharedStyles.row)}
                onClick={() => this.setState({ showFilters: !showFilters })}
              >
                {`${showFilters ? 'Hide Filters' : 'Show Filters'}`}
                <Icon
                  css={css('margin-left: 5px;')}
                  type={showFilters ? 'up' : 'down'}
                />
              </Link>
            </div>
          </div>
        </TableListHeader>

        <TableListHeader
          style={
            ispb
              ? SharedStyles.pbFilterContainer
              : `justify-content: flex-start;`
          }
        >
          {serviceOrgs.canDisplay() && show && showFilters && (
            <Select
              mode="multiple"
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 20%;
                      margin-right: 10px;
                    `
              }
              placeholder={'Select Service Orgs'}
              defaultValue={(serviceOrgs.selected as unknown) as string}
              onChange={value => this.changeSelect('serviceOrgs', value)}
              filterOption={(input, option) => {
                const name = option.props.children as string;
                if (name)
                  return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                return true;
              }}
            >
              {serviceOrgs.opts().map((item, i) => (
                <Select.Option key={i} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          )}

          {dentalOrgs.canDisplay() && show && showFilters && (
            <Select
              mode="multiple"
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 20%;
                      margin-right: 10px;
                    `
              }
              placeholder={'Select Dental Orgs'}
              defaultValue={(dentalOrgs.selected as unknown) as string}
              onChange={value => this.changeSelect('dentalOrgs', value)}
              filterOption={(input, option) => {
                const name = option.props.children as string;
                if (name)
                  return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                return true;
              }}
            >
              {dentalOrgs.opts().map((item, i) => (
                <Select.Option key={i} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          )}

          <Checkbox
            checked={showInactive}
            onChange={() =>
              this.setState({ showInactive: !showInactive }, () =>
                this.retrieveData(true)
              )
            }
          >
            Show Inactive
          </Checkbox>
        </TableListHeader>

        <TableList
          canSelectRows={false}
          loading={loading}
          columns={columns as never[]}
          data={data}
          rowClick={this.selectLocation}
          pageSize={100}
          includeCount="Locations Count"
        />
      </div>
    );
  }
}

export const ManageLocationsComponent = withRouter(_ManageLocationsComponent);
