/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { Component } from 'react';
import { Table, Input, InputNumber, Form, Icon } from 'antd';
import colors from '../colors';

const EditableContext = React.createContext();

class EditableCell extends Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      isRequired,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: isRequired,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class EditableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      editingKey: '',
    };
  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
    if (this.props.setEditing) {
      this.props.setEditing(false);
    }
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.props.dataSource];
      const index = newData.findIndex(item => key === item.key);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      this.setState({ editingKey: '' }, () => {
        this.props.onSave(newData, key, index);
        this.props.setEditing(false);
      });
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
    if (this.props.setEditing) {
      this.props.setEditing(true);
    }
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = [
      ...this.props.columns,
      {
        title: ' ',
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <a onClick={() => this.save(form, record.key)} style={{ marginRight: 8 }}>
                    Save
                  </a>
                )}
              </EditableContext.Consumer>
              <a onClick={() => this.cancel(record.key)}>Cancel</a>
            </span>
          ) : (
            <Icon
              css={css(`color: ${colors.highlight}`)}
              type="edit"
              disabled={editingKey !== ''}
              onClick={() => this.edit(record.key)}
            />
          );
        },
      },
    ].map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: this.props.inputType || 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          isRequired: this.props.isRequired === undefined || this.props.isRequired,
        }),
      };
    });

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          {...this.props}
          size={'small'}
          components={components}
          columns={columns}
          rowClassName={(item, index) => `tbl-list-row-${index % 2} editable-row`}
          pagination={false}
        />
      </EditableContext.Provider>
    );
  }
}

export const EditableFormTable = Form.create()(EditableTable);
