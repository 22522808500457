/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { Tag } from 'antd';
import { get } from 'lodash';
import { SelectValue } from 'antd/lib/select';
import colors from '../colors';
import { AutoComplete } from '../autocomplete';

interface IProps {
  // tslint:disable-next-line: no-any
  dataSource: any[];
  placeholder?: string;
  onTagsChange?: (selected: string[]) => void;
  ref?: (comp: MultiSelectSearchComponent) => void;
  disabled?: boolean;
  selected?: string[];
  limitReached?: boolean;
  useSelectedState?: boolean;
  hide: string[];
}

type Selected = { text: string; value: string };

interface IState {
  value: string;
  selected: string[];
  _selected: Selected[];
}

export class MultiSelectSearchComponent extends Component<IProps, IState> {
  static defaultProps: IProps;
  state: IState = {
    value: '',
    selected: this.props.selected || [],
    _selected: (this.props.selected || []).map(s => ({
      value: get(s, 'value', s),
      text: get(s, 'text', s),
    })),
  };
  tagChange = () => {
    if (this.props.onTagsChange) {
      this.props.onTagsChange(this.state.selected);
    }
  };
  onSelect = (value: SelectValue, option: Object) => {
    setTimeout(() => {
      if (this.props.limitReached) return;
      const state = { ...this.state };
      state.value = '';
      state.selected = [...state.selected, value as string] as string[];
      state._selected = [
        ...state._selected,
        {
          value: value.toString(),
          text: get(option, 'props.children', value.toString()),
        },
      ];
      this.setState(state, this.tagChange);
    }, 10);
  };
  removeSelected = (removedTag: string) => {
    const { _selected } = this.state;

    const _selectedUpdated = _selected.filter(tag => tag.value !== removedTag);
    const selectedUpdated = _selectedUpdated.map(s => s.value);
    this.setState(
      {
        _selected: _selectedUpdated,
        selected: selectedUpdated,
      },
      this.tagChange
    );
  };
  onChange = (value: SelectValue) => {
    this.setState({ value: value as string });
  };
  clearTags = () => {
    this.setState({ selected: [], _selected: [] });
  };
  componentDidMount = () => {
    if (this.props.ref) {
      this.props.ref(this);
    }
  };
  componentDidUpdate(op: IProps) {
    if (op.selected !== this.props.selected) {
      const _selected =
        this.state._selected.length > 0 && this.props.useSelectedState
          ? this.state._selected
          : (this.props.selected || this.state.selected).map(s => ({
              value: get(s, 'value', s),
              text: get(s, 'text', s),
            }));
      this.setState({
        selected: this.props.selected || this.state.selected,
        _selected,
      });
    }
  }
  render() {
    const { value, _selected, selected } = this.state;
    const { placeholder, disabled, dataSource, limitReached, hide } = this.props;
    const sel = selected.map(s => get(s, 'value', s));
    const _dataSource = dataSource.filter(data => {
      const dv = get(data, 'value', data);
      return !sel.includes(`${dv}`) && !hide.includes(`${dv}`);
    });
    const props = {
      dataSource: _dataSource,
      placeholder,
      value,
      onSelect: this.onSelect,
      onChange: this.onChange,
      disabled: disabled || limitReached,
    };

    return (
      <div
        css={css`
          width: 100%;
        `}
      >
        <AutoComplete
          css={css`
            width: 100%;
          `}
          {...props}
          filterOption={(inputValue, option) => {
            return (
              get(option, 'props.children', '')
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            );
          }}
        />
        <div
          css={css`
            margin-top: 5px;
          `}
        >
          {_selected.map(sel => {
            return (
              <Tag
                css={css`
                  margin-bottom: 5px;
                `}
                color={colors.tagDark}
                key={sel.value}
                closable={!disabled}
                onClose={() => this.removeSelected(sel.value)}
              >
                {sel.text}
              </Tag>
            );
          })}
        </div>
      </div>
    );
  }
}

MultiSelectSearchComponent.defaultProps = {
  placeholder: 'Search...',
  dataSource: [{ text: '', value: '' }],
  onTagsChange: () => 0,
  useStateSelected: true,
  hide: [],
} as IProps;
