/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent, ReactNode } from 'react';
import { AppState } from '../../app.state';
import { User, _User } from '../../_shared/interfaces/user';
import { Form, Input, Alert, Modal, message, DatePicker, Checkbox } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import FormTitle from '../../_shared/form-title';
import styles from './edit-location.styles';
import { Button } from '../../_shared/button';
import {
  buildErrorMsgFromForm,
  userHasRole,
  uppercaseWords,
  getMyOrg,
  hasSubHistory,
  setTrialSub,
  setPayedSub,
  isHandpiece,
  cleanCopy,
  cleanPhoneNumber,
  validPhoneNumber,
} from '../../utils';
import Link from '../../_shared/link';
import { get, set, chain, flatten, orderBy, sortBy } from 'lodash';
import NumberedHeader from '../../_shared/numbered-header';
import SharedStyles from '../../_shared/styles';
import {
  getEquipments,
  updateEquipment,
} from '../../_shared/services/manage-equipment.service';
import { TableTransfer } from '../../_shared/table-transfer';
import { Equipment, _Equipment } from '../../_shared/interfaces/equipment';
import { getModels } from '../../_shared/services/manage-models.service';
import { _Model, Model } from '../../_shared/interfaces/model';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { Location as LocationHistory } from 'history';
import React from 'react';
import { _Location, Location } from '../../_shared/interfaces/location';
import {
  createLocation,
  updateLocation,
} from '../../_shared/services/manage-locations.service';
import moment from 'moment';
import { Select } from '../../_shared/select';
import { getUsers } from '../../_shared/services/manage-users.service';
import { getOrgs } from '../../_shared/services/manage-orgs.service';
import { getAppSettings } from '../../_shared/services/manage-app-settings.service';
import { resetHandpiecePresets } from '../../_shared/services/handpiece-profiles.service';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';
import { StatesSelect } from '../../_shared/lib/states';
import { AutoComplete } from '../../_shared/autocomplete';

export interface Opt {
  text: string;
  value: string;
}


interface IProps extends RouteComponentProps, FormComponentProps {
  sState: AppState;
  updateUser: (user: User) => Promise<User>;
  getUserData: () => Promise<{}>;
  updateLocations: () => void;
}

interface TDisplay {
  title: string;
  var: string;
  style?: () => string | string;
  canDisplay?: () => boolean;
  transform?: (value: string) => string;
  options: GetFieldDecoratorOptions;
  data?: () => string[];
  type?: string;
  value?: string;
  opts?: Opt[] | (() => Opt[]);
  subText?: (item: TDisplay) => string | ReactNode;
  disabled?: () => boolean;
  label?: string;
  props?: {};
}

export class _EditLocationComponent extends Component<IProps> {
  // tslint:disable-next-line: no-any
  state: any = {
    loading: false,
    error: null,
    success: null,

    location: undefined,
    back: undefined,
    canLeave: false,
    nextLocation: '',
    trialStart: null,
    screen: 0,
    equipment: [] as Equipment[],
    allUsers: [],
    allLocations: [],
    selectedEquipment: [] as string[],
    eMap: {},
    allOrgs: [],
    trialDuration: undefined,

    toDisplay: [
      {
        title: 'Dental Org',
        var: 'orgId',
        type: 'dropdown',
        canDisplay: () => {
          const isSuper = userHasRole([0, 1, 7], this.props.sState);

          if (isSuper) {
            return true;
          } else {
            const myOrg = getMyOrg(this.props.sState);
            if (myOrg && myOrg.orgType === 0) {
              return true;
            } else {
              return false;
            }
          }
        },
        opts: orderBy(
          this.props.sState.dash.orgs
            .filter(l => {
              return l.orgType === 1;
            })
            .map(l => ({
              value: l.id,
              text: l.name || '',
            })),
          ['text']
        ),
        value: '',
        options: {
          preserve: true,
          rules: [
            {
              required: true,
              message: 'Dental Org is required. ',
            },
          ],
        },
      },
      {
        title: 'Name',
        var: 'name',
        transform: (value: string) => uppercaseWords(value),
        options: {
          preserve: true,
          rules: [
            {
              required: true,
              message: 'Location Name is required. ',
            },
            {
              max: 50,
              message: 'Location Name is limited to 50 characters. ',
            },
          ],
        },
      },
      {
        title: 'Code',
        var: 'code',
        options: {
          preserve: true,
          rules: [
            {
              max: 10,
              message: 'Code is limited to 10 characters. ',
            },
          ],
        },
      },
      {
        title: 'Phone',
        var: 'phone',
        transform: (value: string) => cleanPhoneNumber(value),
        options: {
          preserve: true,
          validateTrigger: 'onBlur',
          rules: [
            {
              // tslint:disable-next-line:no-any
              validator: (rule: any, value: string, cb: any) => {
                const passes = !value || validPhoneNumber(value);
                cb(passes ? undefined : rule.message);
              },
              message: 'Phone needs to be a valid phone number. ',
            },
          ],
        },
      },
      {
        title: 'Address',
        var: 'address.address',
        transform: (value: string) => uppercaseWords(value),
        options: {
          preserve: true,
          rules: [
            {
              required: true,
              message: 'Address is required. ',
            },
          ],
        },
      },
      {
        title: 'Address 2',
        var: 'address.address2',
        transform: (value: string) => uppercaseWords(value),
        options: {
          preserve: true,
        },
      },
      {
        title: 'City',
        var: 'address.city',
        transform: (value: string) => uppercaseWords(value),
        options: {
          preserve: true,
          rules: [
            {
              required: true,
              message: 'City is required. ',
            },
          ],
        },
      },
      {
        title: 'State',
        var: 'address.state',
        type: 'dropdown',
        opts: StatesSelect(),
        transform: (value: string) => (value || '').toUpperCase(),
        style: `
            float: left;
            width: 50%;
            padding-right: 5px;
          `,
        options: {
          preserve: true,
          rules: [
            {
              required: true,
              message: 'State is required. ',
            },
          ],
        },
      },
      {
        title: 'ZIP Code',
        var: 'address.zip',
        style: `
            float: left;
            width: 50%;
            padding-left: 5px;
          `,
        options: {
          preserve: true,
          rules: [
            {
              required: true,
              message: 'Zip Code is required. ',
            },
          ],
        },
      },
    ],
    toDisplay1: [
      {
        title: 'Dental Org Purchase Order #',
        var: 'doPurchaseOrder',
        options: {
          preserve: true,
          rules: [
            {
              max: 50,
              message:
                'Dental Org Purchase Order # is limited to 50 characters. ',
            },
          ],
        },
      },
    ] as TDisplay[],
  };

  submit = async (e?: MouseEvent | FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.state.success = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }

      try {
        const {
          toDisplay,
          toDisplay1,
          location,
          selectedEquipment,
          trialDuration,
        } = this.state;

        const { sState } = this.props;

        const me = new _User(get(sState, 'auth.user')) as User;
        const myOrg = getMyOrg(sState);

        const isEdit = location ? true : false;

        const originalLocation = cleanCopy(location);

        const _location = (new _Location(location) as unknown) as Location;
        flatten([...toDisplay, ...toDisplay1] as TDisplay[]).map(td => {
          const v = td.var;
          const vVal = get(values, v, '');
          const val = td.transform ? td.transform(vVal) : vVal;

          set(_location, v, val);
        });

        const _trialStart = this.props.form.getFieldValue('trialStart');
        const trialStart = _trialStart
          ? moment(_trialStart).startOf('day')
          : undefined;
        const _subscriptionStart = this.props.form.getFieldValue(
          'subscriptionStart'
        );
        const subscriptionStart = _subscriptionStart
          ? moment(_subscriptionStart).startOf('day')
          : undefined;
        const billingPreference = this.props.form.getFieldValue(
          'billingPreference'
        );
        //belongs on equipment;
        delete _location.trialStart;
        delete _location.subscriptionStart;
        delete _location.billingPreference;

        const { eMap } = this.state;

        if (!isEdit) {
          //get the equipment that was selected and update it with new locationId

          if (myOrg && myOrg.orgType === 1) {
            _location.orgId = myOrg.id;
          }
          delete _location.doPurchaseOrder;
          _location.createdBy = me.userId;
          _location.updatedBy = me.userId;

          await createLocation(_location).then(() => {
            this.props.updateLocations();
          });

          trackEvent(eventTypes.location_add, {
            location: _location,
            org: _location.orgId ? myOrg : undefined,
          });
          message.success('Location created Successfully');
        } else if (isEdit) {
          _location.updatedAt = new Date().toISOString();
          _location.updatedBy = me.userId;

          await updateLocation(_location).then(() => {
            this.props.updateLocations();
          });

          trackEvent(eventTypes.location_update, {
            old: originalLocation,
            new: _location,
          });
          message.success('Location updated Successfully');
        }

        selectedEquipment.map((id: string) => {
          const equip = eMap[id] as Equipment;
          const equipHasSubHistory = hasSubHistory(equip);
          let e = new _Equipment(equip) as Equipment;
          const { duration } = trialDuration;
          const me = get(this.props, 'sState.auth.user');
          set(e, `locationId`, _location.id);
          set(e, 'onConsignment', false);
          set(e, 'doPurchaseOrder', _location.doPurchaseOrder);
          set(e, 'updatedAt', moment().toISOString());
          set(e, 'updatedBy', me.userId);
          if (trialStart && !hasSubHistory && !e.alwaysSmart) {
            e = setTrialSub(e, trialStart, duration);
          }
          if (subscriptionStart && !equipHasSubHistory && !e.alwaysSmart) {
            e = setPayedSub(e, subscriptionStart, billingPreference);
          }
          updateEquipment(e);
          if (isHandpiece(e) && myOrg && _location) {
            resetHandpiecePresets(e, myOrg.id, _location.id);
          }
        });

        this.setState(
          {
            loading: false,
          },
          () => {
            this.goBack(true);
          }
        );
      } catch (err) {
        return this.setState({
          error: 'Save failed: ' + err.message,
          loading: false,
        });
      }
    });
  };

  componentDidMount = () => {
    const {
      history: { location },
    } = this.props;

    const loc = get(location, 'state.location');
    const back = get(location, 'state.back', `< Back to Manage Locations`);
    const allLocations: Location[] = get(this.props, 'sState.dash.locations');

    this.retrieveData();

    this.unblock = this.props.history.block(
      (_nextLocation: LocationHistory) => {
        if (!this.state.canLeave) {
          this.state.nextLocation = _nextLocation;
          this.goBack();
          return false;
        }
      }
    );

    this.setState(
      {
        location: loc,
        back,
        allLocations,
      },
      this.setFieldsOriginal
    );
  };
  unblock?: () => void;
  componentWillUnmount = () => {
    this.unblock && this.unblock();
  };
  retrieveData = async () => {
    this.setState({ loading: true });

    const eqs = await getEquipments();
    const models = await getModels();
    const allOrgs = await getOrgs();
    const allUsers = await getUsers();
    const trialDuration = await getAppSettings();

    const modelMap = chain(models)
      .keyBy('id')
      .value();

    const eMap = chain(eqs)
      .keyBy('deviceId')
      .value();

    const equipment = eqs
      .filter(e => !!e.modelId && !!e.equipmentSN)
      .map(e => {
        const model = new _Model(get(modelMap, `${e.modelId}`, {}) as Model);

        return {
          ...e,
          key: e.deviceId,
          type: model.type,
          model: model.name,
        };
      });

    this.setState({
      loading: false,
      equipment,
      allUsers,
      eMap,
      allOrgs,
      trialDuration,
    });
  };
  setFieldsOriginal = () => {
    const { toDisplay, location } = this.state;
    const {
      form: { setFieldsValue },
    } = this.props;

    (toDisplay as TDisplay[]).map(item => {
      const val = get(location, `${item.var}`);
      if (val) {
        const v = item.type === 'datepicker' ? moment(val) : val;
        setFieldsValue({ [item.var]: v });
      }
    });
  };
  goBack = (force = false) => {
    const onOk = () => {
      const { nextLocation } = this.state;
      const { history } = this.props;
      this.state.canLeave = true;
      if (nextLocation) {
        history.push(nextLocation);
      } else {
        history.goBack();
      }
    };

    const onCancel = () => 0;

    const isEdit = this.state.location ? true : false;
    const title = 'Location';

    if (force) {
      onOk();
    } else {
      Modal.confirm({
        title: 'Navigation Confirmation',
        content: `Are you sure you want to navigate away from ${
          isEdit ? 'Edit' : 'Add'
        } ${title}?  The ${isEdit ? '' : 'new'} ${title} will not be ${
          isEdit ? 'saved' : 'created'
        } and any progress will be lost.`,
        onOk,
        onCancel,
        okText: 'Leave',
      });
    }
  };

  onCheck = () => {
    setTimeout(() => {
      const trialStart = this.props.form.getFieldValue('trialStart');
      const subscriptionStart = moment(trialStart)
        .startOf('day')
        .add(6, 'months')
        .add(1, 'day');
      if (trialStart) {
        this.props.form.setFieldsValue({ subscriptionStart });
      }
    }, 100);
  };
  renderTitle = () => {
    const { location, screen } = this.state;

    let comp;
    if (location) {
      comp = <FormTitle>{`Edit Location`}</FormTitle>;
    } else {
      const props = {
        cols: [{ text: `Location Info` }, { text: 'Assign Equipment' }],
        activeIndex: screen,
      };

      comp = (
        <React.Fragment>
          <FormTitle>{`Add Dental Location`}</FormTitle>
          <NumberedHeader {...props} />
        </React.Fragment>
      );
    }
    return <div css={css(`width: 80%; margin-top: 10px;`)}>{comp}</div>;
  };
  equipmentChange = (nextTargetKeys: string[]) => {
    const equips = nextTargetKeys.map(eqId => {
      const equip = this.state.eMap[eqId];
      return equip;
    }).filter(e => !!e);
    const sortedKeys = sortBy(equips, 'equipmentSN').map(eq => {
      return eq.deviceId;
    });
    this.setState({ selectedEquipment: sortedKeys });
  };
  renderScreen = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;

    const {
      toDisplay,
      screen,
      equipment,
      selectedEquipment,
      toDisplay1,
    } = this.state;

    if (screen === 0) {
      return (
        <Form
          layout="vertical"
          css={css(styles.formContainer)}
          onSubmit={this.submit}
        >
          {(toDisplay as TDisplay[]).map((item, _i) => {
            const itemOptions = item.options;
            const canDisplay = item.canDisplay ? item.canDisplay() : true;
            const opts = item.opts as Opt[];
            const sty =
              typeof item.style === 'function' ? item.style() : item.style;
            return !canDisplay ? null : (
              <Form.Item key={_i} css={css(sty)} label={item.title}>
                <div>
                  {getFieldDecorator(item.var, itemOptions)(
                    item.type === 'dropdown' && item.opts ? (
                      <Select>
                        {opts.map((opt, i) => (
                          <Select.Option key={i} value={opt.value}>
                            {get(opt, 'text', get(opt, 'label'))}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : item.type === 'AutoComplete' ? <AutoComplete useDefaultFilter dataSource={opts} /> : item.type === 'datepicker' ? (
                      <DatePicker />
                    ) : (
                      <Input />
                    )
                  )}
                  {item && item.subText && item.subText(item)}
                </div>
              </Form.Item>
            );
          })}
        </Form>
      );
    } else {
      const TableColumns = [
        {
          title: 'Model ID',
          dataIndex: 'modelId',
        },
        {
          title: 'Serial Number',
          dataIndex: 'equipmentSN',
        },
      ];
      const {
        form: { getFieldValue },
      } = this.props;
      const orgId = getFieldValue('orgId');
      const eqs = sortBy(
        (equipment as Equipment[]).filter(e => {
          return e.dentalOrgId === orgId && !e.locationId;
        }),
        'equipmentSN'
      );
      return (
        <div css={css(styles.transferContainer, styles.container)}>
          <TableTransfer
            dataSource={eqs}
            targetKeys={selectedEquipment}
            showSearch={true}
            onChange={this.equipmentChange}
            locale={{
              searchPlaceholder: 'Search by model or serial number',
              emptyText: (
                <span
                  css={css(`font-style: italic;`)}
                >{`No equipment assigned`}</span>
              ),
            }}
            filterOption={(inputValue: string, item: Equipment) =>
              get(item, 'name', '')
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) !== -1 ||
              (get(item, 'modelId', '') as string)
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) !== -1 ||
              get(item, 'equipmentSN', '')
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) !== -1
            }
            leftColumns={TableColumns}
            rightColumns={TableColumns}
          />
          <Form
            layout="vertical"
            css={css(styles.formContainer2, 'margin-top: 10px;')}
            onSubmit={this.submit}
          >
            {(toDisplay1 as TDisplay[]).map((item, _i) => {
              const itemOptions = item.options;
              const canDisplay = item.canDisplay ? item.canDisplay() : true;
              const opts = item.opts as (() => Opt[]);
              const disabled = item.disabled && item.disabled();
              return !canDisplay ? null : (
                <Form.Item key={_i} label={item.title}>
                  <div css={css(`flex-direction: column;`)}>
                    {getFieldDecorator(item.var, itemOptions)(
                      item.type === 'dropdown' && item.opts ? (
                        <Select>
                          {opts().map((opt, i) => (
                            <Select.Option key={i} value={opt.value}>
                              {get(opt, 'text', get(opt, 'label'))}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : item.type === 'datepicker' ? (
                        <DatePicker disabled={disabled} {...item.props} />
                      ) : item.type === 'checkbox' ? (
                        <Checkbox disabled={disabled} {...item.props}>
                          {item.label}
                        </Checkbox>
                      ) : (
                        <Input />
                      )
                    )}
                    {item && item.subText && <p>{item.subText(item)}</p>}
                  </div>
                </Form.Item>
              );
            })}
          </Form>
        </div>
      );
    }
  };
  dir = (num: number) => {
    const { screen } = this.state;
    if (num > 0) {
      this.props.form.validateFieldsAndScroll(async err => {
        if (err) {
          const error = buildErrorMsgFromForm(err);
          return this.setState({ error });
        }

        this.setState({
          screen: screen + num,
        });
      });
    } else {
      this.setState({
        screen: screen + num,
      });
    }
  };

  renderButtons = () => {
    const { loading, screen, location } = this.state;

    let buttons: ReactNode[] = [];

    const isEdit = location ? true : false;
    // const isSuper = userHasRole([0, 1], this.props.sState);
    const myOrg = getMyOrg(this.props.sState);
    // const isSOAdmin = myOrg && myOrg.orgType === 0;

    const cancel = (
      <Button
        title={'Cancel'}
        disabled={loading}
        css={css`
          margin: 0 5px;
        `}
        outline={true}
        onClick={() => this.goBack()}
      />
    );

    const prev = (
      <Button
        title={'Previous'}
        loading={loading}
        css={css`
          margin: 0 5px;
        `}
        onClick={() => this.dir(-1)}
      />
    );

    const next = (
      <Button
        title={'Next'}
        loading={loading}
        css={css`
          margin: 0 5px;
        `}
        onClick={() => {
          this.dir(1);
        }}
      />
    );

    const submit = (
      <Button
        title={'Submit'}
        loading={loading}
        css={css`
          margin: 0 5px;
        `}
        onClick={this.submit}
      />
    );

    if (isEdit) {
      buttons = [cancel, submit];
    } else if (screen === 0) {
      buttons = [cancel, next];
    } else {
      buttons = [prev, cancel, submit];
    }

    return (
      <div css={css(SharedStyles.row, `margin-top: 20px;`)}>
        {buttons.map(b => b)}
      </div>
    );
  };
  render() {
    const { error, success, back } = this.state;

    return (
      <div css={css(styles.container)}>
        {!!back && (
          <Link
            css={css`
              margin-bottom: 5px;
              margin-right: auto;
            `}
            onClick={() => this.goBack()}
          >
            {back}
          </Link>
        )}

        {this.renderTitle()}

        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        {this.renderScreen()}

        {this.renderButtons()}
      </div>
    );
  }
}

export const EditLocationComponent = Form.create()(
  withRouter(_EditLocationComponent)
);
