/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Alert, Form, Input, DatePicker, message, Checkbox, Modal } from 'antd';
import Link from '../../_shared/link';
import { Sidebar } from '../equipment-details/sidebar.component';
import FormTitle from '../../_shared/form-title';
import { Equipment, _Equipment } from '../../_shared/interfaces/equipment';
import { Org } from '../../_shared/interfaces/org';
import { Model } from '../../_shared/interfaces/model';
import {
  buildErrorMsgFromForm,
  findServiceInTypes,
  getEquipConfig,
  getEquipMaint,
  userHasRole,
  isHandpiece,
  handpieceIsDueForService,
} from '../../utils';
import { AppState } from '../../app.state';
import { get, set, size, flatten, uniq } from 'lodash';
import SharedStyles from '../../_shared/styles';
import styles from './manage-service-record.styles';
import { FormComponentProps } from 'antd/lib/form';
import { MultiSelectSearch } from '../../_shared/multi-select-search';
import { Button } from '../../_shared/button';
import {
  serviceType,
  SERIAL,
  SERIALNOTNECESSARY,
  IServiceType,
} from '../../_shared/lib/services';
import moment from 'moment';
import { _Service, Service } from '../../_shared/interfaces/service';
import { createService } from '../../_shared/services/manage-services.service';
import { updateEquipment } from '../../_shared/services/manage-equipment.service';
import { sendDeviceCommand } from '../../_shared/services/manage-schedule.service';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';

interface IProps extends RouteComponentProps, FormComponentProps {
  equipment: Equipment;
  serviceOrg: Org;
  dentalOrg: Org;
  model: Model;
  sState: AppState;
  serviceTypes: IServiceType[];
}

const NOKEY = '_noKey_';

export class _ManageServiceRecordComponent extends Component<IProps> {
  MSearch: MultiSelectSearch | null | undefined;
  state = {
    error: undefined,
    success: undefined,
    loading: false,
    equipment: undefined,
    serviceOrg: undefined,
    dentalOrg: undefined,
    model: undefined,
    serviceRecord: undefined,
    editMode: true,
    acceptDisclaimer: true,
    toDisplay: [
      {
        title: 'Technician Name',
        var: 'techName',
        canDisplay: () => true,
        opts: [],
        styles: `
          float: left;
          width: 62%;
          margin-right: 10px;
        `,
        options: {
          rules: [
            {
              max: 50,
              message: 'Technician Name is limited to 50 characters. ',
            },
          ],
        },
      },
      {
        title: 'Date',
        var: 'serviceDate',
        type: 'datepicker',
        styles: `
          float: left;
        `,
        options: {
          rules: [
            {
              required: true,
              message: 'Date is required. ',
            },
          ],
        },
      },
      {
        title: 'Note',
        var: 'note',
        type: 'textarea',
        placeholder: 'Notes...',
        styles: `clear: both;`,
        options: {
          rules: [
            {
              max: 250,
              message: 'Note is limited to 250 characters. ',
            },
          ],
        },
      },
      {
        title: 'Services',
        var: 'services',
        type: 'AutoComplete',
        hide: [],
        selected: size(this.props.location.state.serviceTypes)
          ? this.props.location.state.serviceTypes.map((s: { name: string }) => s.name)
          : [],
        data: () => {
          const { model, toDisplay } = this.state;
          const selected = get(
            toDisplay.find(td => td.var === 'services'),
            'selected',
            []
          ) as string[];

          const options = [];
          const heads = get(model, 'heads');
          const modelType = get(model, 'type');
          if (modelType) {
            for (let k in serviceType) {
              const st = serviceType[k];
              if (st.type === modelType || st.type === 'both') {
                if (st.heads && heads) {
                  if (heads >= st.heads || typeof st.heads === 'string') {
                    options.push({ text: st.name, value: k });
                  }
                } else {
                  options.push({ text: st.name, value: k });
                }
              }
            }
          }

          return options.filter(o => !selected.find(s => s == o.text));
        },
      },
    ],
  };
  trackEvent = (event = '', data = {}) => {
    trackEvent(event, { ...data, equipment: this.state.equipment });
  };
  componentDidMount = () => {
    const { state } = this.props.location;
    const equipment = get(state, 'equipment');

    if (equipment) {
      this.setState(
        {
          equipment,
          model: get(state, 'model'),
          serviceOrg: get(state, 'serviceOrg'),
          dentalOrg: get(state, 'dentalOrg'),
          serviceRecord: get(state, 'serviceRecord'),
          editMode: get(state, 'editMode', this.state.editMode),
        },
        () => {
          if (this.state.serviceRecord) {
            this.trackEvent(eventTypes.eq_service_view);
          }
          this.setFieldsOriginal();
        }
      );
    }
  };
  confirmDisclaimer = () => {
    return new Promise<void>((resolve, reject) => {
      const hasService =
        this.getSelectedServices().indexOf(get(
          serviceType,
          'hp_general.name',
          ''
        ) as string) > -1;

      if (hasService && this.state.acceptDisclaimer) {
        Modal.confirm({
          title: 'Reset General Runtime',
          content:
            'Selecting this option will reset the handpiece runtime to clear the general maintenance alert',
          onCancel: () => reject(new Error('Save cancelled.')),
          onOk: () => resolve(),
        });
      } else {
        resolve();
      }
    });
  };
  getSelectedServices = () => {
    return get(
      this.state.toDisplay.find(td => td.var === 'services'),
      'selected',
      []
    ) as string[];
  };
  submit = async (e: MouseEvent | FormEvent) => {
    if (userHasRole([6, 7], this.props.sState)) {
      Modal.warn({
        title: 'Functionality Not Available',
        content:
          //tslint:disable-next-line: max-line-length
          'Your user account does not have access to complete this functionality.\
          If you feel that this is incorrect, please contact your company Admin.',
        onOk: () => {
          return 0;
        },
      });

      return;
    }

    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    const services = this.getSelectedServices();
    if (services.length <= 0) {
      Modal.warn({
        title: 'Select a Service',
        content: `Select at least one service to add a service record.`,
        onOk: () => {
          return 0;
        },
      });

      return;
    }

    this.state.loading = true;
    this.state.error = undefined;
    this.state.success = undefined;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }

      try {
        await this.confirmDisclaimer();

        const { sState } = this.props;
        const { serviceRecord, equipment } = this.state;

        const serials: { num: number | string; value?: string }[] = [];
        let defaultnote = '';
        const services = flatten(this.getSelectedServices().map(s => {
          const st = findServiceInTypes(s);
          const hasVal = get(
            values,
            `${SERIAL}${get(st, 'key')}`,
            SERIALNOTNECESSARY
          );
          if (st && st.showSerial && st.heads) {
            serials.push({
              num: st.heads,
              value: hasVal,
            });
          }
          const comps = get(st, 'comps');
          if (comps) { 
            defaultnote = get(st, 'name', '');
            return comps.map(k => ({ [k]: hasVal })); 
          }
          return {
            [get(st, 'key', NOKEY)]: hasVal,
          };
        }));

        const sdate = moment(values.serviceDate).toISOString();
        const deviceId = get(this.state.equipment, 'deviceId', '');
        const serv = new _Service({
          ...(serviceRecord || {}),
          coreid: deviceId,
          techName: values.techName,
          note: (() => { 
            if (values.note || !defaultnote) { 
              return values.note;
            } else {
              return defaultnote;
            }
          })(),
          serviceDate: sdate,
          published_at: sdate,
          services,
        } as Service);

        const me = get(sState, 'auth.user.userId', '');
        serv.createdBy = me;
        serv.updatedBy = me;

        let commands: {
          cmd: string;
          callback?: (success: boolean) => void;
        }[] = [];

        const buildCommand = (
          s: {},
          setSN = false
        ): { cmd: string; callback?: (success: boolean) => void }[] => {
          const k = Object.keys(s)[0];
          const key = (k || '')
            .replace('comp_', '')
            .replace('vac_', '')
            .replace('hp_', '');
          const splitkey = key.split(',');
          const val = get(s, k);
          const stype = get(serviceType, k, {});

          if (get(stype, 'dontSend')) {
            return [];
          } else if (setSN) {
            return [{ cmd: `set_sn,${key},${val}` }];
          } else if (size(splitkey) > 1) {
            return splitkey.map(skey => {
              return {
                cmd: `reset_runtime,${skey}${
                  val !== SERIALNOTNECESSARY ? ',' + val : ''
                }`,
              };
            });
          } else {
            const e: Equipment | undefined = get(this.state, 'equipment');
            if (e && isHandpiece(e)) {
              //DEN-908
              return [
                {
                  cmd: `{ "cmd": "reset_mtr_runtime" }`,
                  callback: success => {
                    if (success) {
                      // DEN-960
                      sendDeviceCommand(
                        e['deviceId'],
                        {
                          arg:
                            '{"cmd":"sensor_instant", "interval": 1, "duration": 1}',
                        },
                        { showErrorToast: true },
                        true
                      );
                    }
                  },
                },
              ];
            } else {
              if (key === NOKEY) { return [] }
              return [
                {
                  cmd: `reset_runtime,${key}${
                    val !== SERIALNOTNECESSARY ? ',' + val : ''
                  }`,
                },
              ];
            }
          }
        };

        if (serviceRecord) {
          const sr = new _Service(serviceRecord);
          serv.createdAt = sr.createdAt;
          serv.createdBy = sr.createdBy;
          const pastServices = get(serviceRecord, 'services', []);
          const pastMap: { [key: string]: string } = {};
          pastServices.map(
            ps => (pastMap[Object.keys(ps)[0]] = ps[Object.keys(ps)[0]])
          );
          commands = flatten(
            serv.services
              .map(s => {
                const k = Object.keys(s)[0];
                if (s[k] === !SERIALNOTNECESSARY) {
                  return [];
                } else {
                  const hasPast = pastMap[k];
                  const notSame = hasPast !== s[k];

                  if (hasPast && notSame) {
                    return buildCommand(s, true);
                  } else if (!hasPast) {
                    return buildCommand(s);
                  } else {
                    return [];
                  }
                }
              })
              .filter(f => f)
          ) as { cmd: string; callback?: (success: boolean) => void }[];
        } else {
          commands = flatten(serv.services.map(s => buildCommand(s))).filter(
            f => f
          ) as { cmd: string; callback?: (success: boolean) => void }[];
        }

        if (commands.length > 0) {
          await Promise.all(
            commands.map(
              async command =>
                await sendDeviceCommand(
                  deviceId,
                  { arg: command.cmd },
                  { showErrorToast: true },
                  isHandpiece(new _Equipment(equipment)),
                  command.callback
                )
            )
          );
        }

        if (serials.length > 0) {
          const equip = new _Equipment(equipment);
          serials.map(s => {
            if (s.value) {
              equip[`mSerial${s.num}`] = s.value;
            }
          });
          set(equip, 'updatedAt', moment().toISOString());
          set(equip, 'updatedBy', me);
          await updateEquipment(equip);
        }

        if (isHandpiece(new _Equipment(equipment)) && serv.services.length) {
          const hpMotorIndex = serv.services.findIndex(s =>
            Object.keys(s).includes('hp_motor')
          );
          if (hpMotorIndex >= 0) {
            const [, hours] = handpieceIsDueForService(
              sState,
              new _Equipment(equipment),
              []
            );
            set(
              serv.services[hpMotorIndex],
              'currentRuntime',
              hours > 0 ? hours * 60 : 0
            ); //store as minutes.
          }
        }
        await createService(serv);
        message.success(
          serviceRecord
            ? 'Service record has been updated'
            : 'Service record has been added'
        );

        this.trackEvent(
          serviceRecord
            ? eventTypes.eq_service_update
            : eventTypes.eq_service_add,
          serviceRecord
            ? {
                old: serviceRecord,
                new: serv,
              }
            : { serviceRecord: serv }
        );

        this.setState(
          {
            loading: false,
          },
          this.goBack
        );
      } catch (err) {
        const ee = get(err, 'error', err.message);
        const showError = (() => {
          if (ee === 'Save cancelled.') {
            return false;
          }
          return true;
        })();

        return this.setState({
          error: showError ? 'Save failed: ' + ee : undefined,
          loading: false,
        });
      }
    });
  };
  setFieldsOriginal = () => {
    const { toDisplay, serviceRecord, equipment } = this.state;
    const {
      form: { setFieldsValue },
      sState,
    } = this.props;
    const { serviceTypes } = this.props.location.state;

    let useMaint: { [x: string]: string }[] = [];

    const setFields = () => {
      if (serviceRecord) {
        toDisplay.map(item => {
          const val = get(serviceRecord, item.var);

          if (item.var === 'services') {
            item.selected = (val || []).map(s => {
              const key = Object.keys(s)[0];
              return { text: get(serviceType[key], 'name'), key };
            });
            this.forceUpdate();
            if (this.MSearch) {
              this.MSearch.setState({
                selected: item.selected,
              });
            }
          } else {
            setFieldsValue({
              [item.var]:
                item.type === 'datepicker'
                  ? moment(val)
                  : val === SERIALNOTNECESSARY
                  ? ''
                  : val,
            });
          }
        });
      } else {
        const maint = getEquipMaint(equipment, sState);
        const data = get(maint, '0.data', {});
        const selected = size(serviceTypes)
          ? serviceTypes.map((s: { name: string }) => s.name)
          : [];
        for (let k in data) {
          const item = data[k];
          if (Array.isArray(item) && item[0] === true) {
            const oldFilters = [
              'comp_airfilter',
              'comp_coafilter',
              'comp_partfilter',
            ];
            if (oldFilters.includes(k)) k = 'comp_filterpack';
            const isOldComp = k.indexOf('comp_') > -1;
            const serv = get(
              serviceType,
              isOldComp ? [k, 'name'] : [`comp_${k}`, 'name'],
              get(serviceType, isOldComp ? [k, 'name'] : [`vac_${k}`, 'name'])
            );
            if (serv) {
              useMaint.push({ [k]: '' });
              selected.push(serv);
            }
          }
        }
        if (this.MSearch) {
          const its = toDisplay.find(v => v.var === 'services');
          if (its) {
            set(its, 'selected', uniq(selected));
            this.MSearch.setState({
              selected: uniq(selected),
            });
          }
        }
      }
    };
    setFields();

    setTimeout(() => {
      const selectedServices = this.getSelectedServices();
      if (
        selectedServices.length > 0 &&
        (serviceRecord || useMaint.length > 0)
      ) {
        const services =
          useMaint.length > 0 ? useMaint : get(serviceRecord, 'services', []);
        services.map(s => {
          const key = Object.keys(s)[0];
          const st = serviceType[key];
          setFieldsValue({
            [`${SERIAL}${key}`]:
              s[key] === SERIALNOTNECESSARY ? '' : s[key],
          });
        });
      }
    }, 500);
  };
  goBack = () => {
    this.props.history.goBack();
  };
  goToBillingHistory = (equipmentId: string) => {};
  cancel = () => {
    const { editMode, serviceRecord } = this.state;
    if (serviceRecord && editMode) {
      return this.setState({ editMode: false });
    }
    this.goBack();
  };
  render() {
    const {
      error,
      success,
      loading,
      equipment,
      model,
      toDisplay,
      editMode,
      serviceRecord,
      acceptDisclaimer,
    } = this.state;
    const { isPhoneBreak } = get(this.props, 'sState.dash.view');
    const disabled = !editMode;
    const {
      form: { getFieldDecorator },
      sState,
    } = this.props;
    const services = this.getSelectedServices();
    const config = getEquipConfig(equipment, sState);
    return (
      <div css={css(styles.container)}>
        <div css={css(SharedStyles.row, `width: 100%;`)}>
          <Link onClick={this.goBack}>{'< Back'}</Link>
        </div>

        <div css={css(styles.container, `flex-direction: row;`)}>
          {equipment && !isPhoneBreak && (
            <Sidebar
              equipment={(equipment as unknown) as Equipment}
              model={model}
              config={config}
              showHeads={true}
              goToBillingHistory={this.goToBillingHistory}
            />
          )}

          <div css={css(styles.mainContainer)}>
            <div css={css(SharedStyles.row)}>
              <FormTitle size={'16px'}>
                {`${
                  serviceRecord && editMode ? 'Edit' : editMode ? 'Add' : ''
                } Service Record`}
              </FormTitle>
            </div>

            <Form
              css={css('max-width: 500px;')}
              layout="vertical"
              onSubmit={this.submit}
            >
              {error && (
                <Alert
                  css={css(SharedStyles.formAlert)}
                  type="error"
                  message={error}
                  closable
                  onClose={() => this.setState({ error: undefined })}
                />
              )}

              {success && (
                <Alert
                  css={css(SharedStyles.formAlert)}
                  type="success"
                  message={success}
                  closable
                  onClose={() => this.setState({ success: undefined })}
                />
              )}

              {toDisplay.map((item, i) => {
                const canDisplay = item.canDisplay ? item.canDisplay() : true;
                const itemOptions = item.options;
                return !canDisplay ? null : (
                  <Form.Item key={i} label={item.title} css={css(item.styles)}>
                    {getFieldDecorator(item.var, itemOptions)(
                      item.type === 'textarea' ? (
                        <Input.TextArea
                          disabled={disabled}
                          rows={5}
                          placeholder={item.placeholder}
                        />
                      ) : item.type === 'AutoComplete' ? (
                        <MultiSelectSearch
                          disabled={disabled}
                          useSelectedState={false}
                          ref={comp => (this.MSearch = comp)}
                          dataSource={item.data && item.data()}
                          placeholder={'Search...'}
                          hide={item.hide}
                          onTagsChange={selected => {
                            let hide = [] as string[];
                            let stypes = selected.map(s => {
                              const value = get(s, 'value', s);
                              const obj = findServiceInTypes(value);
                              if (get(obj, 'comps')) {
                                hide = get(obj, 'comps', []);
                              }
                              return { value: get(obj, 'key', value), text: get(obj, 'name') }
                            });
                            
                            set(item, 'hide', hide);
                            item.selected = stypes.filter(s => !hide.includes(get(s, 'value')));
                            this.forceUpdate();
                          }}
                          selected={item.selected}
                        />
                      ) : item.type === 'datepicker' ? (
                        <DatePicker disabled={disabled} />
                      ) : (
                        <Input disabled={disabled} />
                      )
                    )}
                  </Form.Item>
                );
              })}

              {services.map((item, i) => {
                const st = findServiceInTypes(item);
                return st && st.showSerial ? (
                  <Form.Item key={i} label={`Serial - ${get(st, 'name', '')}`}>
                    {getFieldDecorator(`${SERIAL}${get(st, 'key')}`, {
                      rules: [
                        {
                          required: true,
                          message: 'Serial number is required. ',
                        },
                        {
                          max: 20,
                          message:
                            'Serial number is limited to 20 characters. ',
                        },
                      ],
                    })(<Input disabled={disabled} />)}
                  </Form.Item>
                ) : null;
              })}

              {services.indexOf(get(
                serviceType,
                'hp_general.name',
                ''
              ) as string) > -1 && (
                <div css={css(`margin-bottom: 10px;`)}>
                  <Checkbox
                    checked={acceptDisclaimer}
                    onChange={e =>
                      this.setState({ acceptDisclaimer: e.target.checked })
                    }
                  >
                    Reset General Runtime
                  </Checkbox>
                </div>
              )}

              {(!serviceRecord || editMode) && (
                <Button
                  title={'Cancel'}
                  disabled={loading}
                  css={css`
                    margin-right: 10px;
                  `}
                  outline={true}
                  onClick={this.cancel}
                />
              )}
              {!editMode && (
                <Button
                  title={'Edit'}
                  loading={loading}
                  onClick={() => this.setState({ editMode: !editMode })}
                />
              )}
              {editMode && (
                <Button
                  title={serviceRecord ? 'Save' : 'Add'}
                  loading={loading}
                  onClick={this.submit}
                />
              )}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export const ManageServiceRecordComponent = Form.create()(
  withRouter(_ManageServiceRecordComponent)
);
