import { combineReducers } from 'redux';
import { authenticationReducer } from './authentication/authentication.reducer';
import { dashReducer } from './dashboard/dashboard.reducer';
import { appReducer } from './app.reducer';
import { persistReducer } from 'redux-persist';
import storage from 'localforage';

const persistConfig = (key: string, opts = {}) => ({
  key,
  storage,
  throttle: 10000,
  ...opts,
});

export default combineReducers({
  auth: persistReducer(persistConfig('DEZ-auth'), authenticationReducer),
  dash: persistReducer(
    persistConfig('DEZ-dash', {
      whitelist: [
        'equipment',
        'orgs',
        'locations',
        'groups',
        'orgTypes',
        'view',
        'sensors',
        'models',
        'profiles',
        'users',
      ],
    }),
    dashReducer
  ),
  app: appReducer,// persistReducer(persistConfig('DEZ-app'), appReducer),
});
