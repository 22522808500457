/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { TableHeader } from './table-header.component';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppState } from '../../app.state';
import styles from './manage-equipment.styles';
import { get } from 'lodash';
import { Equipment } from '../../_shared/interfaces/equipment';
import { errorDef, checkHasFilter, findWorstError } from '../../utils';
import { Publish } from '../../_shared/interfaces/publish';
import { Icon } from 'antd';
import {
  Cards,
  getSubCatsFromEquipment,
  renderGrid,
} from './renders.component.';
import { EQStatus } from '../../_shared/interfaces/equipmentStatus';

interface IProps extends RouteComponentProps {
  sState: AppState;
  setLastEquipment: (id?: string) => void;
  addToMap: (pubs: Publish[]) => Publish[];
  setEquipView: (i: number) => void;
}

const PERIODIC_UPDATE = 60000;

interface IState {
  views: { icon: string }[];
  lastUpdate?: number;
}

const Content = {
  noEquip: {
    title: 'No Equipment Found.',
    text: `Your current user account is not currently associated with any equipment.
If this is an error, please contact your organization administrator.`,
    textSearch: `Your current user account is not currently associated with any equipment, 
or there are currently no results from your search. If this is an error, 
please contact your organization administrator.`,
  },
};

export class _ManageEquipmentComponent extends Component<IProps, IState> {
  state: IState = {
    views: [{ icon: 'th-large' }, { icon: 'bars' }],
    lastUpdate: undefined,
  };
  sortEquipment = (equip: Equipment[]) => {
    return equip
      .sort((a, b) => {
        const aMap = get(a, 'status.alerts', []) as EQStatus["alerts"];
        const bMap = get(b, 'status.alerts', []) as EQStatus["alerts"];
        const _a = get(findWorstError(aMap, a), 'error', 0);
        const _b = get(findWorstError(bMap, b), 'error', 0);

        const aName = (a.name || '').toLowerCase();
        const bName = (b.name || '').toLowerCase();

        if (_a === _b) {
          return aName > bName ? 1 : aName < bName ? -1 : 0;
        } else if (_a > 0 || _b > 0) {
          const aDef = errorDef((_a || 0) as number).sort;
          const bDef = errorDef((_b || 0) as number).sort;

          return aDef < bDef ? 1 : -1;
        } else {
          return aName > bName ? 1 : aName < bName ? -1 : 0;
        }
      })
      .filter(e => e.equipmentSN !== 'ACR0000000');
  };
  cardClick = (equipment: Equipment, fromEdit = false) => {
    const cats = getSubCatsFromEquipment(equipment, this.props.sState);

    if (fromEdit) {
      this.props.history.push('/dashboard/editEquipment', {
        equipment,
        ...cats,
      });
    } else {
      this.props.setLastEquipment(equipment.id);
      this.props.history.push('/dashboard/equipmentDetails', {
        equipment,
        ...cats,
      });
    }
  };
  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
    const { sState } = nextProps;
    const loading = get(sState, 'dash.view.equipmentLoading');
    if (loading) { return false }

    const goUpdate = () => {
      this.setState({ lastUpdate: Date.now() })
      return true;
    }

    const timeCheck = !nextState.lastUpdate || Date.now() - nextState.lastUpdate >= PERIODIC_UPDATE;
    if (!loading && timeCheck) {
      return goUpdate();
    } else if (get(sState, 'dash.equipmentFilters') != get(this, 'props.sState.dash.equipmentFilters')) {
      return goUpdate();
    }

    return false;
  }
  render() {
    const { views } = this.state;
    const { sState, setEquipView } = this.props;

    const selectedView = get(sState, 'dash.view.equipView', 0);
    const loading = get(sState, 'dash.view.equipmentLoading');
    const filterEquipment = get(sState, 'dash.filterEquipment');
    const e = get(sState, 'dash.equipment', []);

    const equipment = this.sortEquipment((filterEquipment || e) as Equipment[]);

    const hasFilter = checkHasFilter(sState);
    const props = {
      cardClick: this.cardClick,
      equipment,
      loading,
      sState,
      pageSize: 100,
      hidePageSelect: true,
    };

    return (
      <div css={css(styles.container)}>
        <TableHeader
          views={views}
          selectedView={selectedView}
          onSelectView={setEquipView}
          equipmentCount={equipment.length ? `${equipment.length}` : '0'}
          sState={sState}
        />

        {equipment.length > 0 ? (
          selectedView === 0 ? (
            <Cards {...props} />
          ) : (
            renderGrid(props)
          )
        ) : !loading ? (
          <div css={css(styles.loadingContainer)}>
            <p css={css(styles.noEquipHeader)}>{Content.noEquip.title}</p>
            <p css={css(styles.noEquipText)}>
              {hasFilter ? Content.noEquip.textSearch : Content.noEquip.text}
            </p>
          </div>
        ) : null}

        {equipment.length === 0 && loading && (
          <div css={css(styles.loadingContainer)}>
            <Icon
              type={'loading'}
              css={css`
                font-size: 30px;
              `}
            />
          </div>
        )}
      </div>
    );
  }
}

export const ManageEquipmentComponent = withRouter(_ManageEquipmentComponent);
