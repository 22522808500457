/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styles from './edit-model.styles';
import SharedStyles from '../../_shared/styles';
import { ChangeEvent, FormEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { find, get, isEqual, set, uniq } from 'lodash';
import Link from '../../_shared/link';
import { Button } from '../../_shared/button';
import {
  Alert,
  Form,
  Icon,
  Input,
  Switch,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { AppState } from '../../app.state';
import Select, { OptionProps, SelectValue } from 'antd/lib/select';
import { TableTitleHeader } from '../../_shared/table-list';
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/lib/form/Form';
import { Opt } from '../../_shared/interfaces/opt';
import { Model, Threshold, Thresh, EquipmentType } from '../../_shared/interfaces/model';
import { getModels, updateModel, uploadModelImage } from '../../_shared/services/manage-models.service';
import { DataSourceItemType } from 'antd/lib/auto-complete';
import { AutoComplete } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import getConfig from '../../config';
import Dragger from 'antd/lib/upload/Dragger';
import { RcFile } from 'antd/lib/upload';
import { EditableFormTable } from '../../_shared/editable-table';
import { getSensorsForEquipmentType, sortByKey, uppercaseWords } from '../../utils';

interface EquipmentType_option {
  name: EquipmentType;
  displayName: string;
  defaultThresholds: Thresh;
};

const defaultAmbientThresholds: Thresh = {
  AH: {
    duration: 5,
    minorUpper: 95,
  },
  AT: {
    duration: 5,
    minorLower: 32,
    minorUpper: 120,
  },
};

const equipmentTypes = [
  {
    name: "aerasone_compressor",
    displayName: "Aeras One - Compressor",
    defaultThresholds: defaultAmbientThresholds,
  },
  {
    name: "aerasone_vacuum",
    displayName: "Aeras One - Vacuum",
    defaultThresholds: defaultAmbientThresholds,
  },
  {
    name: "compressor",
    displayName: "Compressor",
    defaultThresholds: defaultAmbientThresholds,
  },
  {
    name: "vacuum",
    displayName: "Vacuum",
    defaultThresholds: defaultAmbientThresholds,
  },
  {
    name: "chair",
    displayName: "Chair",
    supportedSignals: [],
    defaultThresholds: defaultAmbientThresholds,
  },
  {
    name: "handpiece",
    displayName: "Handpiece",
    supportedSignals: [],
    defaultThresholds: defaultAmbientThresholds,
  },
  {
    name: "sterilizer",
    displayName: "Sterilizer",
    supportedSignals: [],
    defaultThresholds: defaultAmbientThresholds,
  },
] as EquipmentType_option[];


interface IProps {
  sState: AppState;
  model?: Model,
  backText: string,
  location: Location;
};

type InputType = "dropdown" | "text" | "autocomplete" | "textarea" | "image" | "boolean";
interface TDisplay {
  title?: string;
  var: string;
  style?: string;
  canDisplay?: () => boolean;
  transform?: (value: string) => string;
  options?: GetFieldDecoratorOptions;
  data?: () => string[];
  opts?: Opt[];
  type: InputType;
  value?: string;
  label?: string;
  disabled?: boolean;
  suffixIcon?: ReactNode;
  completions?: DataSourceItemType[];
  rows?: number;
};

interface IEditComponentConfigProps {
  form: WrappedFormUtils<any>,
  index: number,
};

const emptyModel: Model = {
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  id: '',
  isActive: 1,
  type: 'compressor',
  family: '',
  manufacturer: '',
  name: '',
  image: '',
  images: {},
  heads: 1,
  thresholds: {},
  description: '',
};

function validHeads(equipmentType: string): number[] {
  switch (equipmentType) {
    case "aerasone_compressor":
    case "compressor":
      return [1, 2, 3];
    case "aerasone_vacuum":
    case "vacuum":
    case "handpiece":
      return [1];
    default:
      return [0];
  }
}

const EditImageComponent: React.FC<{
  model: Model,
  imagePath: string,
  className?: string,
  onChange: (filename?: string) => void,
}> = props => {
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const styles = {
    imageContainer: `
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      height: 142px;

      img {
        transition: 0.5s all;
        opacity: 0.0;
        max-height: 100%;
      }  
      
      .anticon {
        position: absolute;
        transition: 0.5s all;
        opacity: 1.0;
        font-size: 40px;
      }
    `,
    hasLoaded: `
      img {
        opacity: 1.0;
      }

      .anticon {
        opacity: 0.0;
      }
    `,
  };

  const onRemove = () => {
    onChange(undefined);
  };

  const { model, imagePath, className, onChange } = props;

  const upload = async (file: RcFile) => {
    let result = await uploadModelImage(file);
    // hacky - to implement custom upload, antd docs suggest
    // using beforeUpload and returning false or a rejected Promise
    // from it to prevent the "action" from being called. leaving
    // "action" undefined just results in the dragger posting the
    // file to the current URL. so we either throw a bogus error
    // here, or hit a 404.
    if (result.data.success) {
      onChange(result.data.filename);
      throw Error(`Upload succeeded`);
    } else {
      console.log(JSON.stringify(result));
      setError(result.data.error || 'upload failed');
      throw Error(`Upload failed: ${result.data.error}`);
    }
  };

  const image = get(model, imagePath, undefined) as string;
  if (image) {
    const fullPath = `${getConfig().model_photos}${image}`;
    return (
      <div
        className={className}
        css={css(styles.imageContainer, hasLoaded && styles.hasLoaded)}
      >
        <img src={fullPath} alt={'Model'} onLoad={(() => setHasLoaded(true))} />
        <Icon type="loading" />
        <Button
          title={'Remove'}
          onClick={onRemove}
        />
      </div>
    );
  } else {
    return (
      <div>
        <Dragger
          name='image'
          height={142}
          multiple={false}
          showUploadList={false}
          accept=".png,.jpg,.jpeg,image/jpeg,image/png"
          beforeUpload={upload}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag image to this area to upload
          </p>
          <p className="ant-upload-error">
            {error}
          </p>
        </Dragger>
      </div>
    );
  }
};

interface EditModelComponentProps {
  sState: AppState;
}

const _EditModelComponent: React.FC<
  RouteComponentProps & FormComponentProps & EditModelComponentProps
> = props => {
  const { form, sState } = props;

  const {
    model,
    backText,
  } = props.location.state as IProps;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [saving, setSaving] = useState<boolean>(false);
  const [workingModel, setWorkingModel] = useState<Model>(model || emptyModel);
  const [models, setModels] = useState<Model[]>([]);
  const [editingThresholds, setEditingThresholds] = useState<boolean>(false);

  const showThresholds = useMemo(
    () => {
      return model ? model.type.includes('aerasone') : false;
    }, [workingModel]
  );

  const showVersions = useMemo(
    () => {
      return ['6400'].includes(get(model, 'id', ''));
    }, [workingModel]
  );

  console.log(`working model: ${JSON.stringify(workingModel)}`);

  const manufacturers = useMemo(
    () => {
      const allMfrs = models.map(m => m.manufacturer || '');
      return uniq(allMfrs).filter(m => m.length > 0);
    },
    [models]
  );

  const families = useMemo(
    () => {
      const allFamilies = models.map(m => m.family || '');
      console.log(`families: ${allFamilies}`);
      return uniq(allFamilies).filter(m => m.length > 0);
    },
    [models]
  );

  const formData: Array<TDisplay[]> = useMemo(() => {
    return [
      [
        {
          title: 'Model ID',
          var: 'id',
          type: 'text',
          disabled: true,
          options: {
            initialValue: workingModel.id || '',
            validateTrigger: 'onChange',
            rules: [
              {
                required: true,
                message: 'Model ID is required',
              },
              {
                max: 15,
                message: 'Model ID is limited to 15 characters.',
              }
            ],
          },
        },
        {
          title: 'Equipment type',
          var: 'type',
          type: 'dropdown',
          opts: equipmentTypes.map(t => ({ label: t.displayName, value: t.name })),
          onSelect: (value: SelectValue) => {
            console.log(`onSelect etype: ${JSON.stringify(value)}`);
          },
          options: {
            initialValue: workingModel.type,
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'Equipment type is required',
              },
            ],
          },
        },
        {
          title: 'Model Name',
          var: 'name',
          type: 'text',
          options: {
            initialValue: workingModel.name,
            validateTrigger: 'onChange',
            rules: [
              {
                required: true,
                message: 'Model name is required',
              },
              {
                max: 75,
                message: 'Name is limited to 75 characters.',
              }
            ],
          },
        },
        {
          title: 'Manufacturer',
          var: 'manufacturer',
          type: 'autocomplete',
          options: {
            initialValue: workingModel.manufacturer,
            validateTrigger: 'onChange',
            rules: [
              {
                required: true,
                message: 'Manufacturer is required.',
              },
              {
                max: 30,
                message: 'Manufacturer is limited to 30 characters.',
              }
            ]
          },
          completions: manufacturers,
          transform: uppercaseWords,
        },
        {
          title: 'Family (optional)',
          var: 'family',
          type: 'autocomplete',
          options: {
            initialValue: workingModel.family,
            validateTrigger: 'onChange',
            rules: [
              {
                max: 30,
                message: 'Family is limited to 30 characters.',
              },
            ]
          },
          completions: families,
          transform: uppercaseWords,
        },
        {
          title: 'Heads',
          var: 'heads',
          type: 'dropdown',
          opts: validHeads(workingModel.type).map(h => ({ value: h, label: h })),
          canDisplay: () => { return validHeads(workingModel.type).length > 1; },
          options: {
            initialValue: workingModel.heads,
            validateTrigger: 'onBlur',
            rules: [
              {
                required: workingModel.type === 'aerasone_compressor',
                message: '# of heads is required for compressors',
              },
            ],
          },
        },
        {
          title: 'Description',
          var: 'description',
          type: 'textarea',
          options: {
            initialValue: workingModel.description,
            validateTrigger: 'onChange',
            rules: [
              {
                max: 500,
                message: 'Description is limited to 500 characters.',
              }
            ]
          },
          rows: 3,
        },
        {
          title: 'Control Board Hardware',
          var: 'control_board_hw',
          type: 'text',
          canDisplay: () => showVersions,
          options: {
            initialValue: workingModel.control_board_hw,
            validateTrigger: 'onChange',
            rules: [
              {
                max: 8,
                message: 'Control Board HW is limited to 8 characters.',
              }
            ]
          },
        },
        {
          title: 'Control Board Firmware',
          var: 'control_board_fw',
          type: 'text',
          canDisplay: () => showVersions,
          options: {
            initialValue: workingModel.control_board_fw,
            validateTrigger: 'onChange',
            rules: [
              {
                max: 8,
                message: 'Control Board FW is limited to 8 characters.',
              }
            ]
          },
        },
        {
          title: 'Daughterboard Firmware Version',
          var: 'fw_version',
          type: 'text',
          canDisplay: () => showVersions,
          options: {
            initialValue: workingModel.fw_version,
            validateTrigger: 'onChange',
            rules: [
              {
                max: 8,
                message: 'Control Board HW is limited to 8 characters.',
              }
            ]
          },
        },
        {
          title: 'Show on Manufacturing',
          var: 'showOnManu',
          type: 'boolean',
          options: {
            initialValue: !!workingModel.showOnManu,
            validateTrigger: 'onChange',
            rules: [
              {
                max: 500,
                message: 'Description is limited to 500 characters.'
              }
            ]
          },
        },
        {
          title: 'Image',
          var: 'image',
          type: 'image',
        },
        {
          title: 'ChairMount0',
          var: 'images.chairMount0',
          type: 'image',
          canDisplay: () => workingModel.type === "chair",
        }
      ]
    ]
  }, [workingModel, families, manufacturers]);

  const goBack = useCallback((edited: boolean) => {
    props.history.goBack();
  }, [props.history]);

  const hasFieldError = (): boolean => {
    const errors = form.getFieldsError();
    if (!errors) {
      return false;
    } else {
      for (let key in errors) {
        if (errors[key]) {
          return true;
        }
      }
    }

    return false;
  };

  const onChange = useCallback(
    (value: SelectValue | string | number | boolean | undefined, variable: string) => {
      console.log(`onChange: value = ${value}, variable = ${variable}`);
      const oldHeads = validHeads(workingModel.type as EquipmentType);
      set(workingModel, variable, value);

      if (variable === 'type') {
        // If equipment type has changed, reinitialize dependent parameters with defaults
        if (value === undefined || value === '') {
          console.log('equipment_type = undefined');
          workingModel.type = 'compressor';
        } else {
          console.log('equipment_type = ' + value);
          let et = find(equipmentTypes, et => et.name === value, undefined);
          if (et) {
            workingModel.thresholds = et.defaultThresholds;
          }
        }

        const newValidHeads = validHeads(workingModel.type as EquipmentType);
        if (!isEqual(oldHeads, newValidHeads)) {
          workingModel.heads = newValidHeads[0];
        }

        switch (workingModel.type as EquipmentType) {
          case "aerasone_compressor":
          case 'aerasone_vacuum':
          case 'handpiece':
          case 'chair':
            workingModel.showOnManu = false;
            break;
          case 'compressor':
          case 'sterilizer':
          case 'vacuum':
            workingModel.showOnManu = true;
            break;
        }

      }

      setWorkingModel({ ...workingModel });
    },
    [workingModel]
  );

  const onSave = useCallback(
    async (e: MouseEvent | FormEvent) => {
      e.preventDefault();
      if (loading) {
        return;
      }

      try {
        setSaving(true);

        formData.map(i => {
          i.map(j => {
            const val = get(workingModel, j.var, undefined);
            if (typeof (val) === 'string' && j.transform) {
              set(workingModel, j.var, j.transform(val));
            }
          })
        })

        const result = await updateModel(workingModel);
        props.history.replace('/dashboard/manageModel', {
          model: result,
        });
      } catch (err) {
        setError(err.Message);
      } finally {
        setSaving(false);
      }
    }, [workingModel, goBack, loading, props.history]
  );

  const renderForm = useMemo(() => {
    const { getFieldDecorator } = form;
    console.log('rendering form');
    return (
      <Form layout="vertical" css={css(styles.formContainer)} onSubmit={onSave}>
        {(formData as [TDisplay[]]).map((items, i) => {
          return (
            <div key={i}>
              {items.map((item, j) => {
                const itemOptions = item.options;
                const canDisplay = item.canDisplay ? item.canDisplay() : true;
                return !canDisplay ? null : (
                  <Form.Item key={j} css={css(item.style)} label={item.title}>
                    <div>
                      {getFieldDecorator(item.var, itemOptions)(
                        item.type === 'dropdown' && item.opts ? (
                          <Select
                            suffixIcon={item.suffixIcon}
                            disabled={item.disabled}
                            onChange={(value: SelectValue) => onChange(value, item.var)}
                          >
                            {item.opts.map((opt, i) => (
                              <Select.Option key={i} value={opt.value}>
                                {opt.label}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : (item.type === 'autocomplete') ? (
                          <AutoComplete
                            onChange={(value: SelectValue) => onChange(value, item.var)}
                            placeholder={item.title}
                            dataSource={item.completions}
                            filterOption={(inputValue, option) =>
                              option.props.children!.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                          />
                        ) : (item.type === 'boolean') ? (
                          <Switch
                            checked={!!workingModel.showOnManu}
                            onChange={(checked: boolean, event: MouseEvent) => onChange(checked, item.var)}
                          />
                        ) : (item.type === 'textarea') ? (
                          <TextArea
                            rows={item.rows || 3}
                            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value, item.var)}
                          />
                        ) : (item.type === 'image') ? (
                          <EditImageComponent
                            css={css('height: 142px; align-items: start;')}
                            model={workingModel}
                            imagePath={item.var}
                            onChange={(filename?: string) => { onChange(filename, item.var); }}
                          />
                        ) : (
                          <Input
                            onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value, item.var)}
                            disabled={item.disabled}
                          />
                        )
                      )}
                    </div>
                  </Form.Item>
                );
              })}
            </div>
          );
        })}
      </Form>
    );
  }, [workingModel, manufacturers, families, form, formData, onChange, onSave]);

  const thresholdColumns = [
    {
      title: 'Sensor ID',
      dataIndex: 'sensorId',
    },
    {
      title: 'Sensor',
      dataIndex: 'sensorName',
    },
    {
      title: 'Units',
      dataIndex: 'suffix',
    },
    {
      title: 'Minor Lower',
      dataIndex: 'minorLower',
      editable: true,
    },
    {
      title: 'Major Lower',
      dataIndex: 'majorLower',
      editable: true,
    },
    {
      title: 'Minor Upper',
      dataIndex: 'minorUpper',
      editable: true,
    },
    {
      title: 'Major Upper',
      dataIndex: 'majorUpper',
      editable: true,
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      editable: true,
    }
  ];

  const allSensors = getSensorsForEquipmentType(workingModel.type, sState);

  interface thresholdRow {
    key: string,
    sensorId: string,
    sensorName: string,
    minorLower?: number;
    majorLower?: number;
    minorUpper?: number;
    majorUpper?: number;
    duration?: number;
    suffix: string;
  };

  const thresholdData: thresholdRow[] = allSensors
    .map(sensor => {
      const sensorId = sensor.id;
      const thresh = get(workingModel.thresholds, sensorId, undefined) as Threshold | undefined;

      return {
        minorLower: (thresh && thresh.minorLower) || undefined,
        majorLower: (thresh && thresh.majorLower) || undefined,
        minorUpper: (thresh && thresh.minorUpper) || undefined,
        majorUpper: (thresh && thresh.majorUpper) || undefined,
        duration: (thresh && thresh.duration) || undefined,
        key: sensorId,
        sensorId,
        sensorName: get(sensor, 'name', ''),
        suffix: get(sensor, 'suffix', ''),
      };
    }).sort((a, b) => sortByKey(a, b, 'sensorName'));

  const onThresholdChanged = async (newData: thresholdRow[], key: string, index: number) => {
    // newData is entire array of thresholdRows passed in
    // key is key of changed row, index is index of changed row in newData
    const newThreshold = newData[index];
    console.log('newThreshold: ' + JSON.stringify(newThreshold));
    set(workingModel, `thresholds.${key}`, {
      minorLower: newThreshold.minorLower,
      minorUpper: newThreshold.minorUpper,
      majorLower: newThreshold.majorLower,
      majorUpper: newThreshold.majorUpper,
      duration: newThreshold.duration,
    });
    console.log('setting working model');
    setWorkingModel({ ...workingModel });
  };

  const renderThresholds = () => {
    if (!showThresholds) return null;
    // no type signature for EditableFormTable props so we bypass type checking by doing this
    const props = {
      dataSource: thresholdData,
      columns: thresholdColumns,
      loading,
      inputType: 'number',
      isRequired: false,
      onSave: onThresholdChanged,
      setEditing: (isEditing: boolean) => setEditingThresholds(isEditing),
    } as any;
    return (
      <EditableFormTable {...props} />
    );
  };

  const renderButtons = () => {
    const cancel = (
      <Button
        title={'Cancel'}
        disabled={loading}
        css={css`
          margin: 0 5px;
        `}
        onClick={() => {
          console.log("cancel pressed");
          return goBack(false);
        }}
      />
    );

    const submitButton = (
      <Button
        title={'Save'}
        loading={saving || loading}
        css={css`
          margin: 0 5px;
        `}
        disabled={saving || loading || hasFieldError() || editingThresholds}
        onClick={onSave}
      />
    );

    return (
      <div css={css(SharedStyles.row, `margin-top: 50px; align-self: center;`)}>
        {cancel}
        {submitButton}
      </div>
    );
  };

  useEffect(() => {
    const work = async () => {
      setLoading(true);
      setModels(await getModels());
      setLoading(false);
    };
    work();
  }, []);

  return (
    <div css={css(styles.container)}>
      {!!backText && (
        <Link
          css={css`
            margin-bottom: 5px;
            margin-right: auto;
          `}
          onClick={() => goBack(false)}
        >
          {backText}
        </Link>
      )}
      {success && (
        <Alert
          css={css(SharedStyles.formAlert)}
          type="success"
          message={success}
          closable
          onClose={() => setSuccess(undefined)}
        />
      )}
      {error && (
        <Alert
          css={css(SharedStyles.formAlert)}
          type="error"
          message={error}
          closable
          onClose={() => setError(undefined)}
        />
      )}

      <div css={css(SharedStyles.row, styles.rowMargin)}>
        <TableTitleHeader>
          {'Edit Model'}
          {loading && <Icon css={css('margin-left: 5px;')} type="loading" />}
        </TableTitleHeader>
      </div>
      <div css={css(SharedStyles.hr)} />

      {workingModel && renderForm}
      {workingModel &&
        (workingModel.type === "aerasone_compressor" || workingModel.type === "aerasone_vacuum") &&
        renderThresholds()}
      {workingModel && renderButtons()}

    </div>
  );
};

export const EditModelComponent = Form.create()(
  withRouter(_EditModelComponent)
);
