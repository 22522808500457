/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { getObjectDiff, sortByKey, getSensorsForEquipmentType } from '../../utils';
import { AppState } from '../../app.state';
import { get, set } from 'lodash';
import { EditableFormTable } from '../../_shared/editable-table';
import { _Model, EquipmentTypeName, Model, Threshold } from '../../_shared/interfaces/model';
import { updateModel } from '../../_shared/services/manage-models.service';
import FormTitle from '../../_shared/form-title';
import Link from '../../_shared/link';
import { Button } from '../../_shared/button';
import { message, Modal, Switch, Table } from 'antd';
import { Row } from '../../_shared/row';
import { Form } from '../../_shared/form';
import { FormProps } from 'antd/lib/form';
import SharedStyles from '../../_shared/styles';
import ModelImage from '../../_shared/model-image';

interface IProps extends RouteComponentProps {
  sState: AppState;
  updateModels: () => Promise<void>;
}

interface thresholdRow {
  key: string,
  sensorId: string,
  sensorName: string,
  minorLower?: number;
  majorLower?: number;
  minorUpper?: number;
  majorUpper?: number;
  duration?: number;
  suffix: string;
};

const COLS = [
  {
    title: 'Sensor ID',
    dataIndex: 'sensorId',
  },
  {
    title: 'Sensor',
    dataIndex: 'sensorName',
  },
  {
    title: 'Units',
    dataIndex: 'suffix',
  },
  {
    title: 'Minor Lower',
    dataIndex: 'minorLower',
    editable: true,
  },
  {
    title: 'Major Lower',
    dataIndex: 'majorLower',
    editable: true,
  },
  {
    title: 'Minor Upper',
    dataIndex: 'minorUpper',
    editable: true,
  },
  {
    title: 'Major Upper',
    dataIndex: 'majorUpper',
    editable: true,
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    editable: true,
  }
];

export class _ManageModelComponent extends Component<IProps> {
  state = {
    show: true,
    loading: true,
    isEdit: false,
    error: undefined,
    success: undefined,

    model: undefined as Model | undefined,
    thresholdData: [] as thresholdRow[],
    modalProps: {
      visible: false,
    }
  };
  componentDidMount = async () => {
    const { history } = this.props;
    const model = get(history, 'location.state.model');

    this.setState(
      {
        model,
      },
      this.retrieveData
    );
  };
  findModelInSState = () => {
    const { sState } = this.props;
    const modelId = get(this.state, 'model.id');
    const models = get(sState, 'dash.models', []) as Model[];
    return new _Model(models.find(m => m.id === modelId) as Model);
  };
  retrieveData = async () => {
    try {
      this.setState({ loading: true });

      await this.props.updateModels();

      const { sState } = this.props;
      const model = this.findModelInSState();

      const allSensors = getSensorsForEquipmentType(model.type, sState);

      //const data = Object.keys(model.thresholds)
      const thresholdData = allSensors
        .map(sensor => {
          const sensorId = sensor.id;
          const thresh = get(model.thresholds, sensorId, undefined) as Threshold | undefined;

          return {
            ...thresh,
            minorLower: thresh && thresh.minorLower || '',
            majorLower: thresh && thresh.majorLower || '',
            minorUpper: thresh && thresh.minorUpper || '',
            majorUpper: thresh && thresh.majorUpper || '',
            duration: thresh && thresh.duration || '',
            key: sensorId,
            sensorId,
            sensorName: get(sensor, 'name', ''),
            suffix: get(sensor, 'suffix', ''),
          };
        })
        .sort((a, b) => sortByKey(a, b, 'id'));

      this.setState({
        show: true,
        loading: false,
        thresholdData,
        model,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        error: err.message,
        loading: false,
      });
    }
  };
  openHardwareModal = () => {
    let form: any;

    const validator = (rule: any, value: string, cb: any) => {
      const regex = /^(\d+\.)?(\d+\.)?(\*|\d+)$/;
      const passes = regex.test(value);
      cb(!value || passes ? undefined : rule.message);
    }

    const opts = {
      options: {
        rules: [
          {
            required: true,
            message: 'Value is required. ',
          },
          {
            max: 8,
            message: 'Value is limited to 8 characters. ',
          },
          {
            validator,
            message: 'Value is not valid. ',
          },
        ],
      },
    }

    const formProps = {
      registerForm: (f: any) => form = f,
      defaults: this.state.model,
      toDisplay: [
        {
          title: 'Control Board Hardware',
          var: 'control_board_hw',
          ...opts,
        },
        {
          title: 'Control Board Firmware',
          var: 'control_board_fw',
          ...opts,
        },
        {
          title: 'Daughter Board Firmware',
          var: 'fw_version',
          ...opts,
        }
      ]
    } as FormProps;

    const close = () => {
      this.setState({ modalProps: { visible: false } })
    }

    const onSubmit = async () => {
      form.validateFieldsAndScroll(async (err: any, vals: any) => {
        if (err) {
          return;
        }

        try {
          const model = { ...(this.state.model || {}), ...vals };
          await updateModel(model);
          close();
          this.retrieveData();
        } catch (err) {
          message.error(err);
        }
      });
    }

    this.setState({
      modalProps: {
        visible: true,
        title: 'Update Hardware & Firmware Versions',
        onCancel: close,
        onOk: onSubmit,
        okText: 'Submit',
        closable: true,
        children: <Form {...formProps} />
      }
    })
  }

  editModel = (model: Model | undefined) => {
    this.props.history.push('/dashboard/editModel', {
      model,
      backText: `< Back`,
    });
  };

  changeModel = async (d = {}) => {
    const model = this.findModelInSState();
    const newmodel = { ...model, ...d };
    await updateModel(newmodel);
    this.retrieveData();
  };
  back = () => {
    this.props.history.goBack();
  };
  render() {
    const { thresholdData, loading, model } = this.state;

    const showThresholds = get('model', 'type', '').includes('aerasone');
    const typeName = model ? EquipmentTypeName(model.type) : '';
    const showHeads = get(model, 'type', '').includes('compressor');
    const showHardware = ['6400'].includes(get(model, 'id', ''));

    return (
      <div>
        <div css={css(SharedStyles.row, 'margin-bottom:10px;')}>
          <Link
            css={css('margin-bottom: 15px; width: 150px')}
            onClick={this.back}
          >
            {'< Back to Manage Models'}
          </Link>
          <div
            css={css`margin-left: auto;`}
          >
            <Button
              css={css('margin-right: 10px;')}
              loading={loading}
              title="Refresh"
              onClick={this.retrieveData}
            />
            <Button
              css={css('float: right;')}
              title="Edit"
              onClick={() => this.editModel(this.state.model)}
            />
          </div>
        </div>
        <Row>
          <FormTitle size={'18px'}>
            {get(model, 'name')}
          </FormTitle>
        </Row>
        <Row>
          Model ID: {get(model, 'id', '')}
        </Row>
        {get(model, 'description', '') && <Row>
          {get(model, 'description', '')}
        </Row>}
        <Row>
          Manufacturer: {get(model, 'manufacturer', '')}
        </Row>
        {get(model, 'family', '') &&
          <Row>
            Family: {get(model, 'family', '')}
          </Row>}
        <Row>
          Type: {typeName}
        </Row>
        {showHeads && <Row>
          Heads: {get(model, 'heads', '')}
        </Row>
        }
        {showHardware &&
          <div>
            <Row>
              Control Board FW Revision: {get(model, 'control_board_fw', '')}
            </Row>
            <Row>
              Control Board HW Revision: {get(model, 'control_board_hw', '')}
            </Row>
            <Row>
              FW Revision: {get(model, 'fw_version', '')}
            </Row>
          </div>}
        <ModelImage model={model} />

        {showThresholds && <Table
          rowKey={(t: thresholdRow) => t.key}
          dataSource={thresholdData}
          columns={COLS}
          loading={loading}
          pagination={false}
        />}

        <Modal {...this.state.modalProps} />
      </div>
    );
  }
}

export const ManageModelComponent = withRouter(_ManageModelComponent);
