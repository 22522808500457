export interface IServiceType {
  name: string;
  showSerial: boolean;
  type: string;
  heads?: number | string;
  showCheck?: boolean;
  dontSend?: boolean; //ignore senddevicecommand
  comps?: string[];
}

export const SERIAL = '__serial__';
export const SERIALNOTNECESSARY = '_serialNotNecessary_';

export const serviceType: {
  [key: string]: IServiceType;
} = {
  comp_motor1: {
    name: 'Motor 1',
    showSerial: true,
    type: 'compressor',
    heads: 1,
  },
  comp_motor2: {
    name: 'Motor 2',
    showSerial: true,
    type: 'compressor',
    heads: 2,
  },
  comp_motor3: {
    name: 'Motor 3',
    showSerial: true,
    type: 'compressor',
    heads: 3,
  },
  comp_filterpack: {
    name: 'Filter Pack',
    showSerial: false,
    type: 'compressor',
  },
  // remove per DentalEZ
  // comp_airfilter: {
  //   name: 'Air Intake Filter',
  //   showSerial: false,
  //   type: 'compressor',
  // },
  // comp_coafilter: {
  //   name: 'Coalescing Filter',
  //   showSerial: false,
  //   type: 'compressor',
  // },
  // comp_partfilter: {
  //   name: 'Particulate Filter',
  //   showSerial: false,
  //   type: 'compressor',
  // },
  comp_desdryers: {
    name: 'Desiccant Tower',
    showSerial: true,
    type: 'compressor',
  },
  comp_des: {
    name: 'Desiccant Cartridge',
    showSerial: false,
    type: 'compressor',
  },
  comp_tpsensor: {
    name: 'Pressure Sensor',
    showSerial: false,
    type: 'compressor',
  },
  comp_amsensor: {
    name: 'Ambient Sensor',
    showSerial: false,
    type: 'compressor',
  },
  comp_dewsensor: {
    name: 'Dew Point Sensor',
    showSerial: false,
    type: 'compressor',
  },
  vac_filterpack: {
    name: 'Filters & Oil',
    showSerial: false,
    type: 'vacuum',
    comps: [
      'vac_mafilter',
      'vac_oilchange',
      'vac_oilfilter',
      'vac_vcfilter',
    ]
  },
  vac_oilchange: {
    name: 'Oil Change',
    showSerial: false,
    type: 'vacuum',
  },
  vac_oilfilter: {
    name: 'Oil Filter',
    showSerial: false,
    type: 'vacuum',
  },
  vac_belt: {
    name: 'Belt',
    showSerial: false,
    type: 'vacuum',
  },
  vac_motor1: {
    name: 'Motor',
    showSerial: true,
    type: 'vacuum',
  },
  vac_pump: {
    name: 'Pump',
    showSerial: true,
    type: 'vacuum',
  },
  vac_vcfilter: {
    name: 'Vacuum Control Filter',
    showSerial: false,
    type: 'vacuum',
  },
  vac_mafilter: {
    name: 'Main Air Filter',
    showSerial: false,
    type: 'vacuum',
  },
  vac_tpsensor: {
    name: 'Vacuum Sensor',
    showSerial: false,
    type: 'vacuum',
  },
  vac_amsensor: {
    name: 'Ambient Sensor',
    showSerial: false,
    type: 'vacuum',
  },
  hp_motorboard: {
    name: 'Replace Motor Control Board',
    showSerial: true,
    type: 'handpiece',
    heads: '_motorboard',
    dontSend: true,
  },
  hp_motor: {
    name: 'Replace Handpiece Motor',
    showSerial: true,
    type: 'handpiece',
    heads: '_motor',
  },
  hp_lcd: {
    name: 'Replace LCD Screen',
    showSerial: false,
    type: 'handpiece',
    dontSend: true,
  },
  hp_tubing: {
    name: 'Replace Handpiece Tubing',
    showSerial: false,
    type: 'handpiece',
    dontSend: true,
  },
  hp_housing: {
    name: 'Replace Housing',
    showSerial: false,
    type: 'handpiece',
    dontSend: true,
  },
  hp_general: {
    name: 'General Maintenance',
    showSerial: false,
    type: 'handpiece',
    showCheck: true,
    dontSend: true,
  },

  chair_controlboard: {
    name: 'Replace control board',
    type: 'chair',
    dontSend: true,
    showSerial: false,
  },
  chair_seatcushion: {
    name: 'Replace seat cushion',
    type: 'chair',
    dontSend: true,
    showSerial: false,
  },
  chair_backrestcushion: {
    name: 'Replace backrest cushion',
    type: 'chair',
    dontSend: true,
    showSerial: false,
  },
  chair_pump: {
    name: 'Replace pump',
    type: 'chair',
    dontSend: true,
    showSerial: false,
  },
  chair_backcylinder: {
    name: 'Replace back cylinder',
    type: 'chair',
    dontSend: true,
    showSerial: false,
  },
  chair_resetcalibration: {
    name: 'Reset chair calibration',
    type: 'chair',
    dontSend: true,
    showSerial: false,
  },
  chair_general: {
    name: 'General maintenance',
    type: 'chair',
    dontSend: true,
    showSerial: false,
  },

  ae1comp_filterpack: {
    name: 'Filter Pack',
    showSerial: false,
    type: 'aerasone_compressor',
    dontSend: true,
  },
  ae1comp_des: {
    name: 'Desiccant Cartridge',
    showSerial: false,
    type: 'aerasone_compressor',
    dontSend: true,
  },
  ae1comp_desdryers: {
    name: 'Desiccant Tower',
    showSerial: true,
    type: 'aerasone_compressor',
    dontSend: true,
  },
  ae1comp_motor1: {
    name: 'Motor 1',
    showSerial: true,
    type: 'aerasone_compressor',
    dontSend: true,
  },
  ae1comp_motor2: {
    name: 'Motor 2',
    showSerial: true,
    type: 'aerasone_compressor',
    dontSend: true,
  },
  ae1comp_motor3: {
    name: 'Motor 3',
    showSerial: true,
    type: 'aerasone_compressor',
    dontSend: true,
  },
  ae1comp_annmaint: {
    name: 'Annual Maintenance Kit',
    showSerial: false,
    type: 'aerasone_compressor',
    dontSend: true,
  },
  ae1comp_ebuilddate: {
    name: 'Equipment Build Date',
    showSerial: false,
    type: 'aerasone_compressor',
    dontSend: true,
  },

  ae1vac_oil: {
    name: 'Oil Change',
    showSerial: false,
    type: 'aerasone_vacuum',
    dontSend: true,
  },
  ae1vac_filterpack: {
    name: 'Filter Pack',
    showSerial: false,
    type: 'aerasone_vacuum',
    dontSend: true,
  },
  ae1vac_airfilter: {
    name: 'Air Filter',
    showSerial: true,
    type: 'aerasone_vacuum',
    dontSend: true,
  },
  ae1vac_oilfilter: {
    name: 'Oil Filter',
    showSerial: true,
    type: 'aerasone_vacuum',
    dontSend: true,
  },
  ae1vac_belt: {
    name: 'Belt',
    showSerial: true,
    type: 'aerasone_vacuum',
    dontSend: true,
  },
  ae1vac_motor: {
    name: 'Motor',
    showSerial: true,
    type: 'aerasone_vacuum',
    dontSend: true,
  },
  ae1vac_pump: {
    name: 'Pump',
    showSerial: true,
    type: 'aerasone_vacuum',
    dontSend: true,
  },
  ae1vac_annmaint: {
    name: 'Annual Maintenance Kit',
    showSerial: false,
    type: 'aerasone_vacuum',
    dontSend: true,
  },
  ae1vac_ebuilddate: {
    name: 'Equipment Build Date',
    showSerial: false,
    type: 'aerasone_vacuum',
    dontSend: true,
  },

  all_other: {
    name: 'Other',
    showSerial: false,
    type: 'both',
    dontSend: true,
  },
};
