import { get } from 'lodash';
import { Location } from '../interfaces/location';
import { removeEmpty } from '../../utils';
import { instance } from './instance';

export const getLocationByOrg = async (location: Location) => {
  const resp = await instance.get(`/location/orgid/${location.orgId}`);
  return get(resp, 'data.location', null) as Location;
};

export const getLocation = async (id: string) => {
  const resp = await instance.get(`/location/id/${id}`);
  return get(resp, 'data.location', null) as Location;
};

export const getLocations = async (where?: {}, shouldFilterActive = true) => {
  let resp = {};
  if (where || shouldFilterActive) {
    where = {
      isActive: { eq: 1 },
      ...(where || {}),
    }
    resp = await instance.post(`/location`, {
      where,
    });
  } else {
    resp = await instance.get(`/location`, { data: {} });
  }
  return get(resp, 'data.locations', []) as Location[];
};

export const createLocation = async (location: Location) => {
  const resp = await instance.post('/location/create', {
    location: removeEmpty(location),
  });
  return get(resp, 'data.location', null) as Location;
};

export const updateLocation = (location: Location) => {
  return createLocation(location);
};

export const deleteLocation = async (locationId: string) => {
  const resp = await instance.delete(`/location/${locationId}`);
  return get(resp, 'data.location', null) as Location;
};
