export interface Threshold {
  minorLower?: number;
  majorLower?: number;
  minorUpper?: number;
  majorUpper?: number;
  duration?: number;
};

export interface Thresh {
  [key: string]: Threshold;
};

export interface InfoCol {
  key: string;
  text: string;
  icon: string;
  suffix?: string;
};

export interface InfoRow {
  title: string;
  columns: InfoCol[];
};

export interface ImageElement {
  chairMount0?: string;
}

export type EquipmentType =
  "aerasone_compressor"
  | "aerasone_vacuum"
  | "compressor"
  | "vacuum"
  | "chair"
  | "handpiece"
  | "sterilizer";

export function EquipmentTypeName(eq: EquipmentType) {
  return eq === "aerasone_compressor" ? "Compressor (Aeras One)"
    : eq === "aerasone_vacuum" ? "Vacuum (Aeras One)"
      : eq === "chair" ? "Chair"
        : eq === "compressor" ? "Compressor"
          : eq === "handpiece" ? "Handpiece"
            : eq === "sterilizer" ? "Sterilizer"
              : eq === "vacuum" ? "Vacuum" : '';
}
export interface Model {
  id: string;
  [key: string]: string | string[] | number | undefined | Thresh | InfoRow[] | ImageElement | boolean;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;

  control_board_fw?: string;
  control_board_hw?: string;
  description: string;
  family?: string;
  fw_version?: string;
  heads: number;
  // array of sensor IDs
  ignoreAlerts?: string[];
  image?: string;
  images?: ImageElement;
  infoRows?: InfoRow[];
  isActive?: number;
  manufacturer?: string;
  name: string;
  modelNumber?: string;
  oldModel?: string;

  showMotor?: boolean;
  showOnManu?: boolean;
  showTandem?: boolean;
  thresholds?: Thresh;
  type: EquipmentType;
};

export class _Model {
  constructor(props?: Model) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  id = '';
  [key: string]: string | string[] | number | undefined | Thresh | InfoRow[] | ImageElement | boolean;
  createdAt = new Date().toISOString();
  createdBy = undefined;
  updatedAt = new Date().toISOString();
  updatedBy = undefined;

  control_board_fw?: string = undefined;
  control_board_hw?: string = undefined;
  description = '';
  family? = '';
  fw_version?: string = undefined;
  heads = 0;
  ignoreAlerts?: string[] = undefined;
  image? = '';
  images?: ImageElement = undefined;
  isActive = 0;
  manufacturer? = '';
  type: EquipmentType = "compressor";
  name = '';
  modelNumber = '';
  infoRows?: InfoRow[] = undefined;
  showMotor?: boolean = undefined;
  showOnManu?: boolean = undefined;
  showTandem?: boolean = undefined;
  thresholds?: Thresh = {};
};
